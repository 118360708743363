import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// material
import {styled} from '@mui/material/styles';
import {
    Box, Button, Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    Stack,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import AppInfoOne from "../../authentication/order/AppInfoOne";
import Constants from "../../../Constants";
import AppInfoTwo from "../../authentication/order/AppInfoTwo";
import AppInfoThree from "../../authentication/order/AppInfoThree";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({theme}) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0.5, 1, 0.5),
}));

const SearchStyle = styled(OutlinedInput)(({theme}) => ({
    width: 440,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    // '&.Mui-focused': {width: 520, boxShadow: theme.customShadows.z8},
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func
};

export default function UserListToolbar({
                                            numSelected,
                                            filterName,
                                            onFilterName,
                                            isLawyer,
                                            lawyers,
                                            lawyerSelected,
                                            handleLawyerChange,
                                            statuses,
                                            orderTypeSelected,
                                            handleOrderTypeChange,
                                            statusSelected,
                                            handleStatusChange,
                                            transactions,
                                            transactionSelected,
                                            handleTransactionChange
                                        }) {


    let transactionFilter = <FormControl sx={{width: 200}}>
        <InputLabel id="demo-simple-select-transaction-label">Transaction</InputLabel>
        <Select
            labelId="demo-simple-select-transaction-label"
            id="demo-simple-select"
            value={transactionSelected?.label}
            label="Transaction"
            onChange={handleTransactionChange}
        >
            {
                transactions.map((transaction) => {
                    return <MenuItem
                        key={transaction.label}
                        value={transaction.value}
                    >
                        {transaction.label}
                    </MenuItem>
                })
            }
        </Select>
    </FormControl>;

    const [searchText, setSearchText] = useState(filterName);
    const [searchEnabled, setButtonEnabled] = useState(true);

    const disableTemporarily = (callback) => {
        setButtonEnabled(false);
        callback(true);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 3000);
    }

    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
        >
            <Grid container spacing={2}>

                <Grid item xs={12} sm={5} md={8}>
                    <Stack direction="row" alignItems="left" spacing={2}>
                        <SearchStyle
                            value={searchText}
                            onChange={(event) => {
                                setSearchText(event.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                    disableTemporarily( (a) => {
                                        onFilterName(searchText);
                                    });
                                }
                            }}
                            placeholder="Search..."
                            startAdornment={
                                <InputAdornment position="start">
                                    <Box component={Icon} icon={searchFill} sx={{color: 'text.disabled'}}/>
                                </InputAdornment>
                            }
                        />
                        <Button variant="contained"
                                disabled={!searchEnabled}
                                onClick={() => {
                                    disableTemporarily( (a) => {
                                        onFilterName(searchText);
                                    });
                                }}>
                            Search
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={7} md={4}>
                    <Stack direction="row" alignItems="left"
                        sx={{
                            display: {xs: 'block', sm: 'flex', md: 'flex'}
                        }}
                    >
                        <FormControl sx={{width: 200}}>
                            <InputLabel id="demo-simple-select-label">Order type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={orderTypeSelected}
                                label="Order Type"
                                onChange={handleOrderTypeChange}
                            >
                                {
                                    ['ALL', 'POLICE_RECORD', 'COMP_CERTIFICATE'].map((status) => {
                                        return <MenuItem
                                            key={status}
                                            value={status}
                                        >
                                            {status}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        {transactionFilter}
                        <FormControl sx={{width: 200}}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={statusSelected}
                                label="Status"
                                onChange={handleStatusChange}
                            >
                                {
                                    statuses.map((status) => {
                                        return <MenuItem
                                            key={status}
                                            value={status}
                                        >
                                            {status}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>
            </Grid>




        </RootStyle>
    );
}
