import {Outlet} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
//
import MainPageDashboardNavbar from './MainPageDashboardNavbar';
import {Alert, AlertTitle} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import * as CommunicationService from "../../services/CommunicationService";
import * as LogicService from "../../services/LogicService";
import * as AlertMapper from "../../services/AlertMapper";
import {getAllPublicFeatureSwitches, getCompanyInUse} from "../../services/CommunicationService";
import {setCompanyInUse} from "../../services/LogicService";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 94;
const APP_BAR_DESKTOP = 152;


// ----------------------------------------------------------------------

export default function MainLayout({backgroundColor, paddingSides}) {
    let options = {
        display: 'flex',
        minHeight: '100%',
        overflow: 'hidden',
    };

    if (backgroundColor != undefined && backgroundColor != null) {
        options.backgroundColor = backgroundColor
    }

    const RootStyle = styled('div')(options);
    function extracted(theme) {
        if (paddingSides != undefined && paddingSides != null) {
            return {
                paddingTop: APP_BAR_DESKTOP + 24,
                paddingLeft: paddingSides,
                paddingRight: paddingSides
            }
        }
        return {
            paddingTop: APP_BAR_DESKTOP + 24,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        };
    }

    const MainStyle = styled('div')(({theme}) => {
        return {
            flexGrow: 1,
            overflow: 'auto',
            minHeight: '100%',
            paddingTop: APP_BAR_MOBILE + 54,
            paddingBottom: theme.spacing(10),
            [theme.breakpoints.up('lg')]: extracted(theme)
        }
    });


    const [banners, setBanners] = useState([]);
    useEffect(() => {
        CommunicationService.getBanners()
            .then((response) => {
                setBanners(response);
            })
            .catch((error) => console.log(error));

        CommunicationService.getAllPublicFeatureSwitches()
            .then((response) => {
                LogicService.setFeatureSwitches(response);
            })
            .catch((error) => console.log(error));

        CommunicationService.getCompanyInUse()
            .then((response) => {
                LogicService.setCompanyInUse(response);
            })
            .catch((error) => console.log(error));

        CommunicationService.getAllPublicPrices()
            .then((response) => {
                let prices = response;
                LogicService.setPublicPrices(prices);
                CommunicationService.getAllPrices(true)
                    .then((rez) => {
                        prices.push(...rez);
                        LogicService.setPublicPrices(prices);
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
    }, []);

    const bigScreen = window.screen.width >= 1300;

    const smallScreenSx = {width: "90%", margin: "10px 0 30px 0", marginLeft: "5%", alignSelf: "center"}
    const bigScreenSx = {width: "50%", margin: "10px 0 30px 0", marginLeft: "25%", alignSelf: "center"}

    return (
        <RootStyle>
            <MainPageDashboardNavbar/>
            <MainStyle>
                {
                    banners.map((b, k) => <Alert sx={bigScreen ? bigScreenSx : smallScreenSx} key={"banner_" + k}
                                            variant="filled" anchorOrigin={{vertical: 'top', horizontal: 'center'}} severity={b.severity} >
                        {b.title !== undefined && b.title !== null ? <AlertTitle>{b.title}</AlertTitle> : null}
                        {b.text}
                    </Alert>)
                }
                <Outlet/>
            </MainStyle>
        </RootStyle>
    );
}
