let reasons = [
    {physicallyMandatory: false, name: "A.D.R"},
    {physicallyMandatory: false, name: "A.N.A.F."},
    {physicallyMandatory: false, name: "A.P.I.A"},
    {physicallyMandatory: false, name: "A.R.R."},
    {physicallyMandatory: true, name: "ACCESARE FONDURI"},
    {physicallyMandatory: false, name: "ACHIZIȚIE ARMĂ/ARMĂ VÂNĂTOARE"},
    {physicallyMandatory: false, name: "ACHIZIȚII"},
    {physicallyMandatory: false, name: "ACTIVITATE TRANSPORT"},
    {physicallyMandatory: false, name: "ADMINISTRAȚIE"},
    {physicallyMandatory: false, name: "ADMINISTRATOR"},
    {physicallyMandatory: true, name: "ADOPȚIE"},
    {physicallyMandatory: false, name: "ALEGERI"},
    {physicallyMandatory: false, name: "ALTE MOTIVE"},
    {physicallyMandatory: false, name: "AMBASADĂ"},
    {physicallyMandatory: false, name: "AMBASADA S.U.A"},
    {physicallyMandatory: false, name: "ANGAJARE"},
    {physicallyMandatory: false, name: "ASISTENT MATERNAL"},
    {physicallyMandatory: false, name: "ASOCIAT/ASOCIAȚIE"},
    {physicallyMandatory: false, name: "ASOCIAŢIA VÂNĂTORILOR"},
    {physicallyMandatory: true, name: "ATESTAT/ATESTARE"},
    {physicallyMandatory: true, name: "AUTORITATEA NAȚIONALĂ PENTRU CETĂȚENIE"},
    {physicallyMandatory: false, name: "AUTORITĂȚI"},
    {physicallyMandatory: false, name: "AUTORIZAȚIE/AUTORIZARE"},
    {physicallyMandatory: false, name: "AVIZ/AVIZARE"},
    {physicallyMandatory: true, name: "BANCĂ"},
    {physicallyMandatory: true, name: "CANDIDATURĂ ALEGERI"},
    {physicallyMandatory: true, name: "CĂSĂTORIE"},
    {physicallyMandatory: false, name: "CETĂȚEAN DE ONOARE"},
    {physicallyMandatory: true, name: "CETĂȚENIE"},
    {physicallyMandatory: false, name: "COLEGIUL ASISTENȚILOR MEDICALI"},
    {physicallyMandatory: false, name: "COLEGIUL ASISTENȚILOR SOCIALI"},
    {physicallyMandatory: false, name: "COLEGIUL CONSILIERILOR JURIDICI"},
    {physicallyMandatory: false, name: "COLEGIUL MEDICILOR"},
    {physicallyMandatory: false, name: "COLEGIUL MEDICILOR DENTIȘTI"},
    {physicallyMandatory: false, name: "COLEGIUL MEDICILOR VETERINARI"},
    {physicallyMandatory: false, name: "COLEGIUL NAȚIONAL DE ADMINISTRAȚIE"},
    {physicallyMandatory: false, name: "COLEGIUL NAȚIONAL DE APĂRARE"},
    {physicallyMandatory: false, name: "COLEGIUL PSIHOLOGILOR"},
    {physicallyMandatory: false, name: "COMISIE"},
    {physicallyMandatory: true, name: "CONCURS/EXAMEN"},
    {physicallyMandatory: false, name: "CURSURI"},
    {physicallyMandatory: false, name: "D.G.A.S.M.B"},
    {physicallyMandatory: false, name: "D.G.A.S.P.C."},
    {physicallyMandatory: true, name: "DOBÂNDIREA UNEI CETĂȚENII STRĂINE"},
    {physicallyMandatory: false, name: "DOSAR"},
    {physicallyMandatory: false, name: "DOSAR COMPENSAȚII CF. HG 447/2017"},
    {physicallyMandatory: false, name: "DREPT DE MUNCĂ"},
    {physicallyMandatory: false, name: "DREPT DE ȘEDERE"},
    {physicallyMandatory: false, name: "EMIGRARE"},
    {physicallyMandatory: true, name: "EXAMEN AUTO/MOTO"},
    {physicallyMandatory: false, name: "FUNDAȚIE"},
    {physicallyMandatory: true, name: "GRAȚIERE"},
    {physicallyMandatory: false, name: "I.S.C.T.R"},
    {physicallyMandatory: true, name: "IMIGRARE"},
    {physicallyMandatory: false, name: "ÎMPUTERNICIT FIRMĂ"},
    {physicallyMandatory: false, name: "ÎNCREDINȚARE MINOR"},
    {physicallyMandatory: false, name: "ÎNDEPLINIREA CALITĂŢII DE MENTOR Conf. Lg.335/2013"},
    {physicallyMandatory: false, name: "ÎNFIINȚARE ASOCIAȚIE"},
    {physicallyMandatory: false, name: "ÎNFIINȚARE BIROU MEDIATOR"},
    {physicallyMandatory: false, name: "ÎNFIINȚARE CABINET MEDICAL INDIVIDUAL"},
    {physicallyMandatory: false, name: "ÎNFIINȚARE FEDERAȚIE"},
    {physicallyMandatory: false, name: "ÎNFIINȚARE O.N.G."},
    {physicallyMandatory: false, name: "ÎNFIINȚARE ORGANIZAȚIE"},
    {physicallyMandatory: false, name: "ÎNFIINȚARE P.F.A."},
    {physicallyMandatory: false, name: "ÎNFIINŢARE PARTID POLITIC"},
    {physicallyMandatory: false, name: "ÎNFIINŢARE SOCIETATE COMERCIALĂ"},
    {physicallyMandatory: false, name: "ÎNREGISTRARE ÎN SCOP DE T.V.A"},
    {physicallyMandatory: false, name: "ÎNSOŢITOR"},
    {physicallyMandatory: false, name: "ÎNSOȚITOR MINOR ÎN STRĂINĂTATE"},
    {physicallyMandatory: true, name: "INSPECTORATUL GENERAL PENTRU IMIGRĂRI"},
    {physicallyMandatory: false, name: "INSTANȚA DE JUDECATĂ"},
    {physicallyMandatory: false, name: "INSTITUȚIE PUBLICĂ"},
    {physicallyMandatory: false, name: "INSTRUCTOR"},
    {physicallyMandatory: false, name: "ÎNTREPRINDERE INDIVIDUALĂ"},
    {physicallyMandatory: false, name: "LIBER PROFESIONIST/LIBERĂ PRACTICĂ"},
    {physicallyMandatory: false, name: "LICENȚĂ"},
    {physicallyMandatory: false, name: "LICENȚĂ TAXI"},
    {physicallyMandatory: false, name: "LICENȚĂ TRANSPORT"},
    {physicallyMandatory: false, name: "LICITAȚIE"},
    {physicallyMandatory: false, name: "LOCUL DE MUNCĂ/SERVICIU"},
    {physicallyMandatory: false, name: "MĂSURA 112"},
    {physicallyMandatory: false, name: "MĂSURA 121"},
    {physicallyMandatory: false, name: "MĂSURA 123"},
    {physicallyMandatory: false, name: "MĂSURA 141"},
    {physicallyMandatory: false, name: "MĂSURA 41.112"},
    {physicallyMandatory: false, name: "MĂSURA 421 PRIN 121"},
    {physicallyMandatory: false, name: "MEMBRU ASOCIAȚIE"},
    {physicallyMandatory: false, name: "MUNCĂ ÎN STRĂINĂTATE"},
    {physicallyMandatory: false, name: "O.A.M.G.M.A.M.R."},
    {physicallyMandatory: false, name: "O.N.R.C."},
    {physicallyMandatory: false, name: "O.P.C"},
    {physicallyMandatory: false, name: "O.R.N.I.S.S."},
    {physicallyMandatory: false, name: "OBȚINERE AUTORIZAȚIE TRADUCĂTOR ȘI INTERPRET"},
    {physicallyMandatory: true, name: "OBȚINERE CETĂȚENIE ROMÂNĂ"},
    {physicallyMandatory: true, name: "OBȚINERE CETĂȚENIE S.U.A."},
    {physicallyMandatory: false, name: "OPERATOR DISPECER"},
    {physicallyMandatory: false, name: "OPERATOR TRANSPORT RUTIER"},
    {physicallyMandatory: false, name: "ORDINUL ARHITECȚILOR"},
    {physicallyMandatory: false, name: "ORDINUL ASISTENȚILOR MEDICALI"},
    {physicallyMandatory: false, name: "ORDINUL BIOLOGILOR"},
    {physicallyMandatory: false, name: "ORDINUL CONSILIERILOR JURIDICI"},
    {physicallyMandatory: false, name: "ORDINUL FARMACIȘTILOR"},
    {physicallyMandatory: false, name: "ORDINUL MEDICILOR"},
    {physicallyMandatory: false, name: "ORDINUL PRACTICIENILOR DE MEDICINĂ COMPLEMENTARĂ"},
    {physicallyMandatory: false, name: "ORDINUL TEHNICIENILOR DENTARI"},
    {physicallyMandatory: false, name: "ORGANIZAȚIE NON - GUVERNAMENTALĂ"},
    {physicallyMandatory: false, name: "ORGANIZAȚIE PROFESIONALĂ"},
    {physicallyMandatory: true, name: "PARCHET"},
    {physicallyMandatory: true, name: "PARLAMENTUL ROMÂNIEI"},
    {physicallyMandatory: true, name: "PATRIARHIA ROMÂNĂ"},
    {physicallyMandatory: false, name: "PATRONAT"},
    {physicallyMandatory: true, name: "PERMIS PORT ARMĂ"},
    {physicallyMandatory: true, name: "PERMIS REZIDENȚĂ"},
    {physicallyMandatory: true, name: "PERMIS ȘEDERE"},
    {physicallyMandatory: true, name: "PERMIS VÂNĂTOARE"},
    {physicallyMandatory: false, name: "PERSOANĂ DESEMNATĂ"},
    {physicallyMandatory: false, name: "PERSOANĂ FIZICĂ AUTORIZATĂ"},
    {physicallyMandatory: false, name: "PLĂTITOR ACCIZE"},
    {physicallyMandatory: false, name: "PLĂTITOR T.V.A./T.V.A. U.E"},
    {physicallyMandatory: false, name: "PRACTICĂ"},
    {physicallyMandatory: false, name: "PRELUNGIRE AUTORIZAȚIE"},
    {physicallyMandatory: false, name: "PRELUNGIRE PERMIS PORT ARMĂ"},
    {physicallyMandatory: false, name: "PRELUNGIRE VIZĂ DE ȘEDERE/CARTE DE REZIDENȚĂ"},
    {physicallyMandatory: true, name: "PRESCHIMBARE PAȘAPORT"},
    {physicallyMandatory: true, name: "PRESCHIMBARE PERMIS DE CONDUCERE STRĂIN"},
    {physicallyMandatory: false, name: "PRESTĂRI SERVICII"},
    {physicallyMandatory: false, name: "PROCEDURĂ ADMINISTRATIVĂ/JUDICIARĂ"},
    {physicallyMandatory: true, name: "PROGRAM"},
    {physicallyMandatory: false, name: "PROGRAM A.F.N."},
    {physicallyMandatory: false, name: "PROGRAM F.E.A.D.R."},
    {physicallyMandatory: false, name: "PROGRAM H2B"},
    {physicallyMandatory: false, name: "PROGRAM L.E.A.D.R."},
    {physicallyMandatory: false, name: "PROGRAM P.O.C.A."},
    {physicallyMandatory: false, name: "PROGRAM POST C.C.E."},
    {physicallyMandatory: false, name: "PROGRAM S.A.P.A.R.D"},
    {physicallyMandatory: false, name: "PROGRAMUL CASA VERDE"},
    {physicallyMandatory: true, name: "PROIECT"},
    {physicallyMandatory: false, name: "PROIECTANT SISTEME ALARMARE/SECURITATE"},
    {physicallyMandatory: true, name: "REABILITARE"},
    {physicallyMandatory: false, name: "REATESTARE/REAUTORIZARE"},
    {physicallyMandatory: false, name: "REAVIZARE FIRMĂ DE PAZĂ"},
    {physicallyMandatory: false, name: "RECUNOAȘTERE CĂSĂTORIE"},
    {physicallyMandatory: false, name: "RECUNOAȘTERE PROFESIONALĂ/STUDII"},
    {physicallyMandatory: false, name: "RECUNOAȘTERE/ECHIVALARE DIPLOME"},
    {physicallyMandatory: false, name: "REDOBÂNDIRE CETĂȚENIE"},
    {physicallyMandatory: false, name: "REDOBÂNDIRE CETĂȚENIE ROMÂNĂ"},
    {physicallyMandatory: true, name: "REDOBÂNDIRE PERMIS AUTO"},
    {physicallyMandatory: false, name: "REG. OPERATORILOR CU PROD. ACCIZABILE"},
    {physicallyMandatory: false, name: "REGISTRUL ARHITECTILOR"},
    {physicallyMandatory: false, name: "REGISTRUL ASISTENȚILOR SOCIALI"},
    {physicallyMandatory: false, name: "REGISTRUL AUDITORILOR FINANCIARI"},
    {physicallyMandatory: false, name: "REGISTRUL AUTO ROMÂN"},
    {physicallyMandatory: false, name: "REGISTRUL COMERȚULUI"},
    {physicallyMandatory: false, name: "REGISTRUL CONCILIATORILOR"},
    {physicallyMandatory: false, name: "REGISTRUL INTERMEDIARILOR DE CREDITE"},
    {physicallyMandatory: false, name: "REGISTRUL NAȚIONAL EVALUATORI RISC"},
    {physicallyMandatory: false, name: "REGISTRUL OPERATORILOR INTRACOMUNITARI"},
    {physicallyMandatory: false, name: "REGISTRUL OPERATORILOR REGIONALI"},
    {physicallyMandatory: false, name: "REGISTRUL PLĂTITORILOR DE PRODUSE ACCIZABILE"},
    {physicallyMandatory: false, name: "REGISTRUL SPECIALIŞTILOR CONSTATARE DAUNE"},
    {physicallyMandatory: false, name: "REGISTRUL UNIC EUROPEAN"},
    {physicallyMandatory: false, name: "REGISTRUL URBANIȘTILOR DIN ROMÂNIA"},
    {physicallyMandatory: false, name: "REÎNNOIRE AUTORIZAȚIE DE IMPORTATOR"},
    {physicallyMandatory: false, name: "REÎNNOIRE LICENȚIERE SISTEME ALARMARE"},
    {physicallyMandatory: false, name: "REÎNTREGIREA FAMILIEI"},
    {physicallyMandatory: false, name: "RELICENȚIERE FIRMĂ"},
    {physicallyMandatory: false, name: "RELICENȚIERE SOCIETATE"},
    {physicallyMandatory: true, name: "RENUNȚARE CETĂȚENIE"},
    {physicallyMandatory: true, name: "RENUNȚARE LA CETĂȚENIA ROMÂNĂ"},
    {physicallyMandatory: false, name: "REPATRIERE"},
    {physicallyMandatory: false, name: "REPREZENTARE COMPANIE"},
    {physicallyMandatory: false, name: "RESPONSABIL DE SECURITATE"},
    {physicallyMandatory: false, name: "RESPONSABIL SUBSTANŢE CLASIFICATE"},
    {physicallyMandatory: true, name: "REZIDENȚĂ"},
    {physicallyMandatory: true, name: "REZIDENŢĂ S.U.A."},
    {physicallyMandatory: false, name: "REZIDENȚIAT"},
    {physicallyMandatory: true, name: "SCHIMBARE SEDIU SOCIAL"},
    {physicallyMandatory: true, name: "ȘCOALĂ AUTO/MOTO"},
    {physicallyMandatory: false, name: "ȘCOLARIZARE"},
    {physicallyMandatory: false, name: "SEMNĂTURĂ ELECTRONICĂ"},
    {physicallyMandatory: false, name: "SERVICE CASE DE MARCAT"},
    {physicallyMandatory: false, name: "SINDICAT"},
    {physicallyMandatory: false, name: "SOCIETATE"},
    {physicallyMandatory: false, name: "SOCIETATE COMERCIALĂ/PROFESIONALĂ"},
    {physicallyMandatory: false, name: "SOCIETATEA DE INVESTIȚII FINANCIARE"},
    {physicallyMandatory: false, name: "SOCIETATEA DE MEDICINĂ LEGALĂ"},
    {physicallyMandatory: false, name: "SOCIETATEA DE ȘTIINȚE MATEMATICE"},
    {physicallyMandatory: false, name: "SOCIETATEA ROMÂNĂ DE STATISTICĂ"},
    {physicallyMandatory: false, name: "SOCIETATEA SCRIITORILOR ROMÂNI"},
    {physicallyMandatory: false, name: "TRANSPORT MARFĂ/VALORI/PERSOANE"},
    {physicallyMandatory: false, name: "UNIUNEA ARHITECȚILOR DIN ROMÂNIA"},
    {physicallyMandatory: false, name: "UNIUNEA ARTIȘTILOR PLASTICI"},
    {physicallyMandatory: false, name: "UNIUNEA EUROPEANĂ"},
    {physicallyMandatory: false, name: "UNIUNEA JURIȘTILOR DIN ROMÂNIA"},
    {physicallyMandatory: false, name: "UNIUNEA NAȚIONALĂ A BAROURILOR DIN ROMÂNIA"},
    {physicallyMandatory: false, name: "UNIUNEA TRANSPORTATORILOR"},
    {physicallyMandatory: false, name: "UNIUNEA ZIARIŞTILOR PROFESIONIŞTI"},
    {physicallyMandatory: false, name: "VALIDARE CONSILIER/PRIMAR"},
    {physicallyMandatory: true, name: "VIZĂ"},
    {physicallyMandatory: true, name: "VIZĂ DE LUCRU"},
    {physicallyMandatory: true, name: "VIZĂ S.U.A"},
    {physicallyMandatory: true, name: "VIZĂ ȘEDERE ÎN STRĂINĂTATE"},
    {physicallyMandatory: true, name: "VIZĂ ȘEDERE TEMPORARĂ"},
    {physicallyMandatory: false, name: "VIZITĂ PENITENCIAR STRĂINĂTATE"},
    {physicallyMandatory: false, name: "VOLUNTARIAT"}
]

let cerReasons =
    [
        {physicallyMandatory: false, name: "ALTE MOTIVE"},
        {physicallyMandatory: false, name: "ADOPTIE"},
        {physicallyMandatory: false, name: "ANGAJARE"},
        {physicallyMandatory: false, name: "ASISTENT MATERNAL"},
        {physicallyMandatory: false, name: "CONCURS"},
        {physicallyMandatory: false, name: "CURATOR"},
        {physicallyMandatory: false, name: "D.G.A.S.P.C."},
        {physicallyMandatory: false, name: "DOSAR"},
        {physicallyMandatory: false, name: "LOCUL DE MUNCA"},
        {physicallyMandatory: false, name: "TITULARIZARE"},
        {physicallyMandatory: false, name: "VOLUNTARIAT"}

    ]

const getReasons = (name) => {
    return reasons.map(r => {
        return {code: r, name: r}
    })
}

export {
    reasons,
    cerReasons,
    getReasons
}