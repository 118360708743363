import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import * as LogicService from '../../../services/LogicService'

import {Box, Button, Stack, Typography} from '@mui/material';
import PriceOffer from "./PriceOffer";


export default function OrderOffer() {
    const navigate = useNavigate();

    const toPayment = () => {
        LogicService.verifyTransactionAndReturn();
        navigate('/complete-offer');
    }

    const back = () => {
        LogicService.undefineMyOrder();
        navigate('/');
    }

    return (
        <Box>
            <Typography variant="subtitle2">
                <PriceOffer/>
            </Typography>

            <Typography variant="h6" sx={{marginBottom: "10px"}}>
                Încarcă documentele necesare pentru a continua
            </Typography>
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color={'warning'}
                    onClick={back}
                >
                    Inapoi
                </Button>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={toPayment}
                >
                    Încărcare documente
                </Button>
            </Stack>

        </Box>
    );
}
