import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContactCard from "../../../pages/ContactCard";
import MessageForm from "../../authentication/order/MessageForm";
import ReportMessageForm from "../../authentication/order/ReportMessageForm";
import DragAndDropFileUploader from "../app/DragAndDropFileUploader";
import {Stack, TextField, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import * as LogicService from "../../../services/LogicService";
import * as Yup from "yup";
import * as AlertMapper from "../../../services/AlertMapper";
import * as CommunicationService from "../../../services/CommunicationService";
import {useState} from "react";
// import {PDFDocument} from 'pdf-lib' PDF LIBRARIE BUNA
import JSZip from "jszip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

export default function UnsupportedOrderTypeModal({
                                                      open,
                                                      handleConfirm,
                                                      handleClose,
                                                      url,
                                                      title,
                                                      content,
                                                      neededFiles = []
                                                  }) {

    let [files, setFiles] = React.useState([]);

    let browserData = LogicService.getBrowserData();

    let RegisterSchema = Yup.object().shape({
        reason: Yup.string().required('Motivul este necesar').min(3, 'Prea scurt').max(500, 'Prea lung').matches(/.*/, 'Mesajulul este invalid'),
        message: Yup.string().required('Mesajul este necesar').min(3, 'Prea scurt').max(500, 'Prea lung').matches(/.*/, 'Mesajulul este invalid'),
        email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este necesar').max(50, 'Prea lung'),
        cnp: Yup.string().required('CNP-ul este necesar').max(13, 'Prea lung').min(13, 'Prea scurt'),
        fullName: Yup.string().required('Numele este necesar').max(50, 'Prea lung').min(5, 'Prea scurt'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            reason: '',
            message: '',
            cnp: '',
            fullName: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });

    const downloadURL = (data, fileName) => {
        const a = document.createElement('a')
        a.href = data
        a.download = fileName
        document.body.appendChild(a)
        a.style.display = 'none'
        a.click()
        a.remove()
    }

    async function getAsByteArray(file) {
        return new Uint8Array(await readFile(file))
    }

    function readFile(file) {
        return new Promise((resolve, reject) => {
            // Create file reader
            let reader = new FileReader()
            // Register event listeners
            reader.addEventListener("loadend", e => resolve(e.target.result))
            reader.addEventListener("error", reject)
            // Read file
            reader.readAsArrayBuffer(file)
        })
    }

    const zipMyFiles = async (files, callback) => {
        const zip = new JSZip();

        for (const file of files) {
            const byteFile = await getAsByteArray(file)
            zip.file(file.name, byteFile, {base64: true});
        }

        zip.generateAsync({type: "blob"}).then(async function (content) {
            const blob = new Blob([content], {
                type: 'application/octet-stream',
                name: 'ceva.zip'
            })

            callback(blob);
            // const url = window.URL.createObjectURL(blob)
            // downloadURL(url, "example.zip")
            // setTimeout(() => window.URL.revokeObjectURL(url), 1000)
        });
    };


    let setDropdownFilesAndMerge = (files) => {
        setFiles(files);
    }

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 5000);
    }

    const buttonPress = () => {
        if (Boolean(touched.email && errors.email)
            || Boolean(touched.reason && errors.reason)
            || Boolean(touched.fullName && errors.fullName)
            || Boolean(touched.cnp && errors.cnp)
            || Boolean(touched.message && errors.message)
        ) {
            return;
        }

        let ceva = []
        for (var i = 0; i < localStorage.length; i++) {
            ceva.push(localStorage.key(i) + ": " + localStorage.getItem(localStorage.key(i)).toString())
        }

        let clientMessage = {}
        clientMessage = {
            email: getFieldProps('email').value,
            fullName: getFieldProps('fullName').value,
            cnp: getFieldProps('cnp').value,
            reason: getFieldProps('reason').value,
            message: getFieldProps('message').value,
            browserData: browserData,
            currentURL: window.location.href,
            localStrg: ceva
        }
        disableTemporarily();
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);

        zipMyFiles(files, (blob) => {
            let fileName = new Date().toISOString().replaceAll('.', ':') + ".zip";
            let file = new File([blob], fileName, {
                type: 'application/x-zip',
                path: fileName,
                lastModified: new Date().getTime()
            });
            CommunicationService.sendMessageWithFile(clientMessage, file, true)
                .then((response) => {
                    window.open(url, '_blank', 'noreferrer')
                    handleConfirm();
                })
                .catch((error) => {
                    window.open(url, '_blank', 'noreferrer')
                    LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error))
                });
        })
    }

    const {errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError} = formik;

    const processReasonChange = (event) => {
        let newText = event.target.value;
        setFieldValue('message', newText)
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb: 5}}>
                    {content}
                </DialogContentText>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3} sx={{mt: 2}}>
                            <TextField
                                fullWidth
                                autoComplete="email"
                                type="email"
                                label="Email"
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                            <TextField
                                fullWidth
                                label="Nume complet"
                                {...getFieldProps('fullName')}
                                error={Boolean(touched.fullName && errors.fullName)}
                                helperText={touched.fullName && errors.fullName}
                            />
                            <TextField
                                fullWidth
                                label="CNP"
                                {...getFieldProps('cnp')}
                                error={Boolean(touched.cnp && errors.cnp)}
                                helperText={touched.cnp && errors.cnp}
                            />
                            <TextField
                                fullWidth
                                label="Motivul eliberării"
                                {...getFieldProps('reason')}
                                error={Boolean(touched.reason && errors.reason)}
                                helperText={touched.reason && errors.reason}
                            />
                            <TextField
                                fullWidth
                                label="Detalii suplimentare"
                                multiline
                                rows={4}
                                {...getFieldProps('message')}
                                error={Boolean(touched.message && errors.message)}
                                onChange={processReasonChange}
                                helperText={touched.message && errors.message}
                            />

                            <Typography variant={"body1"} justifyContent={"left"} align={"left"}>
                                Încarcă documentele necesare:
                            </Typography>

                            <Stack direction="row" sx={{marginTop: "5px !important"}}  flexWrap="wrap">
                                {
                                    neededFiles.map(file => <Chip sx={{marginBottom: '10px', marginRight: '10px'}} icon={<DocumentScannerIcon/>} label={file}/>)
                                }
                            </Stack>


                            <Stack direction={{xs: 'column', sm: 'row', marginTop: "5px"}}
                                   sx={{margin: "0% 1%"}}
                                   spacing={0} alignSelf={"center"}>
                                <DragAndDropFileUploader setFiles={setDropdownFilesAndMerge} limit={5}/>
                            </Stack>

                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={buttonPress}
                                disabled={
                                    !buttonEnabled
                                    || !touched.email || Boolean(touched.email && errors.email)
                                    || !touched.fullName || Boolean(touched.fullName && errors.fullName)
                                    || !touched.cnp || Boolean(touched.cnp && errors.cnp)
                                    || !touched.reason || Boolean(touched.reason && errors.reason)
                                    || !touched.message || Boolean(touched.message && errors.message)
                                    || files.length === 0
                                }
                            >
                                Trimiteți cererea
                            </Button>
                        </Stack>
                    </Form>
                </FormikProvider>

            </DialogContent>
        </Dialog>

    );
}
