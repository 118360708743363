import Page from '../../components/Page';
import * as React from "react";
import {useEffect, useRef, useState} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS, setPublicPrices} from "../../services/LogicService";
import Foto_2 from "./images/certificat-de-integritate-comportamentala-online-2.jpg"
import Foto_3 from "./images/certificat-de-integritate-comportamentala-online-3.jpg"
import Foto_4 from "./images/certificat-de-integritate-comportamentala-online-4.jpg"
import Foto_5 from "./images/certificat-de-integritate-comportamentala-online-5.jpg"
import Foto_6 from "./images/certificat-de-integritate-comportamentala-online-6.jpg"
import Foto_cta from "./images/certificat-de-integritate-comportamentala-online-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";
import * as AlertMapper from "../../services/AlertMapper";
import ReportProblemModal from "../../components/_dashboard/user/ReportProblemModal";
import UnsupportedOrderTypeModal from "../../components/_dashboard/user/UnsupportedOrderTypeModal";
import {Button, Grid} from "@mui/material";
import OrderRegisterCard from "../../components/_dashboard/app/OrderRegisterCard";
import CertificatRegisterCard from "../../components/_dashboard/app/CertificatRegisterCard";
import * as CommunicationService from "../../services/CommunicationService";
import {getAllPrices, getAllPublicPrices} from "../../services/CommunicationService";

export default function MainPageIntegritateComportamentalaV2({browserDataCheck}) {

    const [reportProblemModalEnabled, setReportProblemModalEnabled] = useState(false);

    const updateShowFormAndScroll = () => {
        setReportProblemModalEnabled(true)
        executeScroll();
    }

    const executeScroll = () => {
        window.scrollTo(0, 0);
        // myRef.current.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start',
        // })
    }

    useEffect(() => {
        browserDataCheck();

        CommunicationService.getAllPublicPrices()
            .then((response) => {
                let prices = response;

                CommunicationService.getAllPrices(true)
                    .then((rez) => {
                        prices.push(...rez);
                        LogicService.setPublicPrices(prices);
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
    }, []);

    let keywords = [
        "eliberare certificat de integritate comportamentală online",
        "obținere certificat de integritate comportamentală online",
        "certificat de integritate comportamentală online preț"
    ]

    return (
        <Page title={`Cazier Judiciar Online integritate comportamentala`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description"
                      content={"Indiferent dacă este vorba de aspirații profesionale, educaționale sau îndeplinirea formalităților pentru relocare internațională, un certificat de integritate comportamentală este adesea un document indispensabil."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Certificat de integritate comportamentală online - Obțineți repede și fără efort certificatul de
                    integritate comportamentală online</title>

                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>

            {/*<UnsupportedOrderTypeModal*/}
            {/*    open={reportProblemModalEnabled}*/}
            {/*    handleClose={() => {*/}
            {/*        setReportProblemModalEnabled(false);*/}
            {/*    }}*/}
            {/*    handleConfirm={() => {*/}
            {/*        setReportProblemModalEnabled(false);*/}
            {/*        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);*/}
            {/*    }}*/}
            {/*    url={'https://buy.stripe.com/dR63d8fhU1AM0hOfnJ2s'}*/}
            {/*    title={"Introdu-ți detaliile mai jos"}*/}
            {/*    content={"Pentru a elibera Certificatul de Integritate Comportamentală avem nevoie de câteva detalii despre tine"}*/}
            {/*    neededFiles={["Carte de identitate / Pașaport"]}*/}
            {/*/>*/}

            {
                    <Grid container spacing={5} sx={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item xs={12} md={6} lg={6}
                              sx={{
                                  mt: {xs: 6, sm: 6, md: 6},
                                  mb: {xs: 1, sm: 6, md: 6}
                              }}
                              className="orderRegisterCard">
                            <CertificatRegisterCard/>
                        </Grid>
                    </Grid>
            }

            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center padding-more">
                            <h1>Certificat de integritate comportamentală online</h1>
                            <p>Indiferent dacă este vorba de aspirații profesionale, educaționale sau îndeplinirea
                                formalităților pentru
                                relocare internațională, un certificat de integritate comportamentală este adesea un
                                document
                                indispensabil.</p>

                            <p>Acesta atestă lipsa antecedentelor penale și este o dovadă a bunei dumneavoastră
                                conduite. Caziere.ro
                                facilitează întregul proces, transformând obținerea unui certificat de integritate
                                comportamentală
                                online într-o experiență lipsită de stres și complicaţii.</p>
                            <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                                    onClick={() => updateShowFormAndScroll()}>
                                <i className="fas fa-download custom-icon"></i> Obțineți certificat de integritate
                                comportamentală
                            </Button>
                        </div>


                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți repede și fără efort certificatul de integritate comportamentală online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => updateShowFormAndScroll()}>
                        <i className="fas fa-download custom-icon"></i> Obțineți certificat de integritate
                        comportamentală
                    </Button>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">

                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3}
                                 alt="Certificat de integritate comportamentală online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <h2>Ce este certificatul de integritate comportamentală online</h2>
                            <p>Certificatul de integritate comportamentală online, cunoscut și sub denumirea de cazier
                                judiciar online,
                                joacă un rol crucial în validarea trecutului unei persoane din punct de vedere al
                                infracțiunilor penale.
                                Acest document este adesea cerut de angajatori, instituții educaționale sau pentru
                                formalități legale,
                                ca o dovadă a bunei conduite a solicitantului.</p>

                            <p>Obținerea certificatului de integritate comportamentală se poate face acum facil prin
                                platforme online
                                dedicate precum caziere.ro, evitând astfel procedurile îndelungate și vizitele la
                                instituțiile publice.
                                Aceste servicii oferă posibilitatea de completare a formularului, încărcarea
                                documentației necesare și
                                primirea certificatului în format digital sau fizic, simplificând semnificativ procesul
                                de obținere a
                                cazierului judiciar.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Situații în care aveți nevoie de un certificat de integritate comportamentală
                                online</h2>
                            <p>Un certificat de integritate comportamentală devine indispensabil în mai multe contexte,
                                facilitând
                                procesele de verificare și îmbunătățind șansele individuale în diverse oportunități
                                profesionale,
                                sociale sau legale. Acum aveți posibilitatea să obțineți online certificatul de
                                integritate
                                comportamentală, procesul fiind mult mai simplu și mai rapid, eliminând barierele
                                geografice și de
                                timp. </p>

                            <p><strong>Este posibil să aveți nevoie de acest document pentru:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Angajare: Multe companii solicită acest certificat ca parte
                                    a
                                    procesului de recrutare.
                                </li>
                                <li dir="ltr" aria-level="1">Educație: Înscrierea la unele programe academice poate
                                    necesita
                                    prezentarea unui cazier curat.
                                </li>
                                <li dir="ltr" aria-level="1">Relocare: Emigrarea sau obținerea cetățeniei într-o altă
                                    țară adesea
                                    cere acest document.
                                </li>
                                <li dir="ltr" aria-level="1">Obţinere licențe: Anumite profesii solicită un cazier
                                    judiciar curat
                                    pentru obținerea licenței de practică.
                                </li>
                                <li>Adopție: Procesul de adopție poate implica verificarea antecedentelor penale.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți repede și fără efort certificatul de integritate comportamentală online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => updateShowFormAndScroll()}>
                        <i className="fas fa-download custom-icon"></i> Obțineți certificat de integritate
                        comportamentală
                    </Button>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Eliberare certificat de integritate comportamentală online - de ce să apelați la
                                noi</h2>
                            <p>Apelând la serviciile noastre pentru eliberarea certificatului de cazier judiciar online,
                                veți beneficia de
                                un proces optimizat și eficient, care vă permite să evitați formalitățile complexe și
                                timpul pierdut la
                                ghișee. Experiența noastră vă asigură că toate demersurile necesare se vor desfășura
                                rapid și fără bătăi de
                                cap, iar echipa noastră este dedicată să vă ofere cele mai bune soluții, adaptându-se
                                necesităților
                                specifice fiecărui caz. </p>

                            <p>Fie că aveți nevoie de cazier judiciar pentru angajare, studii sau orice altă
                                formalitate, noi suntem aici
                                pentru a vă asista în tot procesul, de la depunerea documentelor până la primirea
                                certificatului direct la
                                domiciliu sau în orice loc din lume.</p>


                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_4}
                                 alt="Certificat de integritate comportamentală online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p><strong>Apelând la serviciile noastre veți beneficia de:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Proces simplificat: Demarăm procedura imediat ce primim
                                    documentele necesare de
                                    la dumneavoastră.
                                </li>
                                <li dir="ltr" aria-level="1">Rapiditate: Timpul este prețios, iar noi ne angajăm să
                                    reducem la minimum
                                    timpul de așteptare.
                                </li>
                                <li dir="ltr" aria-level="1">Flexibilitate: Putem elibera certificatul de integritate
                                    comportamentală pentru
                                    orice cetățean român sau străin.
                                </li>
                                <li dir="ltr" aria-level="1">Siguranță: Toate informațiile dumneavoastră sunt gestionate
                                    cu cea mai mare
                                    discreție.
                                </li>
                                <li>Suport continuu: Echipa noastră vă stă la dispoziție pentru orice nelămuriri sau
                                    asistență.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți repede și fără efort certificatul de integritate comportamentală online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => updateShowFormAndScroll()}>
                        <i className="fas fa-download custom-icon"></i> Obțineți certificat de integritate
                        comportamentală
                    </Button>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Etapele obținerii unui certificat de integritate comportamentală online</h2>
                            <p>Multe activități, inclusiv obținerea unui loc de muncă, susținerea unor examene,
                                înscrierea la anumite
                                cursuri și programe sau dobândirea cetățeniei unei alte țări, necesită verificarea
                                antecedentelor penale
                                ale unei persoane.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_5}
                                 alt="Certificat de integritate comportamentală online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <p>În era digitală, procesul de obținere a acestor documente a devenit mai accesibil și mai
                                eficient,
                                eliminând necesitatea de a călători sau de a aștepta la cozi lungi. Procesul de obținere
                                a
                                certificatului dumneavoastră de integritate comportamentală online este simplificat
                                maxim pentru
                                confortul dumneavoastră și implică următoarele etape:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Înregistrare: Completați formularul online și încărcați
                                    documentele
                                    necesare.
                                </li>
                                <li dir="ltr" aria-level="1">Verificare: Documentele sunt revizuite de echipa noastră.
                                </li>
                                <li dir="ltr" aria-level="1">Procesare: Gestionăm formalitățile în numele dumneavoastră.
                                </li>
                                <li>Livrare: Primirea cazierului scanat, urmată de primirea fizică prin curier rapid.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Acte necesare pentru un certificat de integritate comportamentală online </h2>
                            <p>Eliberarea certificatului de integritate comportamentală online se poate face online și
                                ușor acum în
                                România. Folosind serviciile platformei caziere.ro de eliberare a acestor acte oficiale,
                                puteți uita
                                de timpul pierdut stând la coadă. Timp pierdut înseamnă și bani pierduți. Pentru a
                                demara procedura
                                de obținere a certificatului de integritate comportamentală online, aveți nevoie de:</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">O copie clară a actului de identitate sau a pașaportului.
                                </li>
                                <li dir="ltr" aria-level="1">O poză (selfie) ținând actul de identitate.
                                </li>
                                <li dir="ltr" aria-level="1">Contract semnat online, prin care ne împuterniciți să
                                    gestionăm
                                    procesul în numele dumneavoastră.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți repede și fără efort certificatul de integritate comportamentală online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => updateShowFormAndScroll()}>
                        <i className="fas fa-download custom-icon"></i> Obțineți certificat de integritate
                        comportamentală
                    </Button>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Certificat de integritate comportamentală online - preț</h2>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_6}
                                 alt="Certificat de integritate comportamentală online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Costul pentru obținerea certificatului de integritate comportamentală online se
                                calculează în funcție de
                                mai multe criterii: persoană fizică sau juridică, regim de urgență, necesitatea
                                traducerii legalizate și
                                limbă de traducere, apostilarea Haga și modalitatea de livrare. Fiecare client
                                beneficiază de un
                                serviciu personalizat conform nevoilor specifice.</p>
                            <p>Pentru a asigura transparența totală în ceea ce privește prețul obținerii online a unui
                                certificat de
                                integritate comportamentală, utilizatorii pot obține o estimare preliminară a prețului
                                direct pe
                                platforma online, înainte de a finaliza comanda. Această facilitate permite clienților
                                să facă alegeri
                                informate și să se asigure că serviciul se încadrează în bugetul disponibil.</p>

                            <p>Totodată, la finalizarea procesului de eliberare a certificatului, clientul va primi
                                detaliat pe factura
                                electronică toate costurile implicate, oferind o viziune clară asupra modului în care au
                                fost alocate
                                resursele financiare pentru eliberarea documentului.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți repede și fără efort certificatul de integritate comportamentală online!</h2>
                    <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}}
                            onClick={() => updateShowFormAndScroll()}>
                        <i className="fas fa-download custom-icon"></i> Obțineți certificat de integritate
                        comportamentală
                    </Button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de certificatul de integritate comportamentală online? Contactați-ne pentru
                                detalii!</h2>
                            <p>Pentru cei care au nevoie de un certificat de integritate comportamentală rapid și
                                eficient, soluția
                                noastră online vă stă la dispoziție. Vă oferim un serviciu complet, de la înregistrarea
                                cererii până
                                la livrarea documentului dorit. Echipa noastră se ocupă de tot procesul necesar
                                obținerii acestui
                                certificat, astfel încât să nu fie nevoie să vă deplasați.</p>

                            <p>Pentru mai multe informații sau în cazul în care aveți întrebări specifice, suntem aici
                                să vă ajutăm.
                                Nu ezitați să ne contactați la adresa de e-mail <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro </a>!
                                Echipa noastră este pregătită să vă ofere toată asistența necesară!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
