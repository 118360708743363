// material
import {
    Box,
    Stack,
    Typography
} from '@mui/material';

import * as React from "react";
import {DropzoneArea} from 'material-ui-dropzone'
import "../../authentication/order/OrderForm.css"
import { createStyles, makeStyles } from '@material-ui/core/styles';

export default function DragAndDropFileUploader({setFiles, limit = 1}) {
    let handleChange = (files) => {
        setFiles(files);
    }

    const useStyles = makeStyles(theme => createStyles({
        previewChip: {
            minWidth: 160,
            maxWidth: 210,
            marginTop: 15
        },
    }));

    const classes = useStyles();

    return <Box sx={{margin: "5px 0px 10px 0px", width: "100%"}} dir="ltr">
        <DropzoneArea
            dropzoneText={"Trage un fișier aici sau Alege un fișier"}
            acceptedFiles={["image/*", "application/pdf"]}
            filesLimit={limit}
            showAlerts={false}
            onChange={handleChange}

            showPreviews={false}
            showPreviewsInDropzone={true}
            useChipsForPreview
            previewGridProps={{container: { spacing: 1, direction: 'row' }}}
            previewChipProps={{classes: { root: classes.previewChip } }}
            previewText="Fișiere selectate"
        />
    </Box>
}
