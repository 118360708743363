import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Avatar, Box, Drawer, Link, Typography} from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import {MHidden} from '../../components/@material-extend';
//
import account from '../../_mocks_/account';
import * as CommunicationService from "../../services/CommunicationService";
import gridFill from "@iconify/icons-eva/grid-fill";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import pricetagsFill from "@iconify/icons-eva/pricetags-fill";
import toggleFill from "@iconify/icons-eva/toggle-right-outline";
import hddFill from "@iconify/icons-eva/hard-drive-fill";
import linkFill from "@iconify/icons-eva/link-2-fill";
import percentFill from "@iconify/icons-eva/percent-fill";
import statisFill from "@iconify/icons-eva/trending-up-fill";
import bannersFill from "@iconify/icons-eva/alert-triangle-fill";
import defaultsIcon from "@iconify/icons-eva/code-fill";
import logsIcon from "@iconify/icons-eva/menu-2-fill";
import invoiceIcon from "@iconify/icons-eva/file-text-outline";
import brushFill from "@iconify/icons-eva/brush-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import blacklistFill from "@iconify/icons-eva/close-square-fill";
import queueFill from "@iconify/icons-eva/email-outline";
import {Icon} from "@iconify/react";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({isOpenSidebar, onCloseSidebar}) {
    const {pathname} = useLocation();

    const getIcon = (name) => <Icon icon={name} width={22} height={22}/>;

    const sidebarConfigMapped = {
        ORDERS: {
            title: 'orders',
            path: '/admin/orders',
            icon: getIcon(gridFill),
            disabled: false
        },
        ADMIN: {
            title: 'admin',
            path: '/admin/app',
            icon: getIcon(pieChart2Fill),
            disabled: false
        },
        // USER: {
        //     title: 'users',
        //     path: '/admin/user',
        //     icon: getIcon(peopleFill),
        //     disabled: false
        // },
        INVOICES: {
            title: 'invoices',
            path: '/admin/invoices',
            icon: getIcon(shoppingBagFill),
            disabled: false
        },
        PRICES: {
            title: 'prices',
            path: '/admin/prices',
            icon: getIcon(pricetagsFill),
            disabled: false
        },
        COUNTRY_PRICES: {
            title: 'country prices',
            path: '/admin/country-prices',
            icon: getIcon(pricetagsFill),
            disabled: false
        },
        FEATURE_SWITCHES: {
            title: 'feature switches',
            path: '/admin/feature-switches',
            icon: getIcon(toggleFill),
            disabled: false
        },
        EMAIL_SENDER: {
            title: 'email sender',
            path: '/admin/email-sender',
            icon: getIcon(queueFill),
            disabled: false
        },
        DRIVE: {
            title: 'drive',
            path: '/admin/drive',
            icon: getIcon(hddFill),
            disabled: false
        },
        PAYMENT_LINKS: {
            title: 'payment links',
            path: '/admin/payment-links',
            icon: getIcon(linkFill),
            disabled: false
        },
        COMPANIES: {
            title: 'companies',
            path: '/admin/Companies',
            icon: getIcon(percentFill),
            disabled: false
        },
        STATISTICS: {
            title: 'statistics',
            path: '/admin/statistics',
            icon: getIcon(statisFill),
            disabled: false
        },
        BLACKLIST: {
            title: 'blacklist',
            path: '/admin/blacklist',
            icon: getIcon(blacklistFill),
            disabled: false
        },
        MESSAGE_QUEUE: {
            title: 'Message queue',
            path: '/admin/message-queues',
            icon: getIcon(queueFill),
            disabled: false
        },
        BANNERS: {
            title: 'banners',
            path: '/admin/banners',
            icon: getIcon(bannersFill),
            disabled: false
        },
        DEFAULTS: {
            title: 'defaults',
            path: '/admin/defaults',
            icon: getIcon(defaultsIcon),
            disabled: false
        },
        COMMUNICATION_LOGS: {
            title: 'Communication Logs',
            path: '/admin/communication-logs',
            icon: getIcon(logsIcon),
            disabled: false
        },
        E_FACTURA: {
            title: 'E-Factura',
            path: '/admin/e-factura',
            icon: getIcon(invoiceIcon),
            disabled: false
        },
        TESTING_BENCH: {
            title: 'Testing Bench',
            path: '/admin/testing-bench',
            icon: getIcon(brushFill),
            disabled: false
        },
        PRODUCTS: {
            title: 'product',
            path: '/admin/products',
            icon: getIcon(shoppingBagFill),
            disabled: false
        },
        BLOG: {
            title: 'blog',
            path: '/admin/blog',
            icon: getIcon(fileTextFill),
            disabled: false
        }
    }
    let userType = CommunicationService.getUserType();

    let userIsModerator = userType === 'ROLE_MODERATOR';
    let userIsAdmin = userType === 'ROLE_ADMIN';


    let sidebarConfig = [
        sidebarConfigMapped.ORDERS
    ];


    if (userIsAdmin || userIsModerator) {
        // sidebarConfig.push(sidebarConfigMapped.USER);
        sidebarConfig.push(sidebarConfigMapped.INVOICES);
        sidebarConfig.push(sidebarConfigMapped.E_FACTURA);
        sidebarConfig.push(sidebarConfigMapped.EMAIL_SENDER);
        sidebarConfig.push(sidebarConfigMapped.PRICES);
        sidebarConfig.push(sidebarConfigMapped.STATISTICS);
        sidebarConfig.push(sidebarConfigMapped.COUNTRY_PRICES);
        sidebarConfig.push(sidebarConfigMapped.FEATURE_SWITCHES);
        sidebarConfig.push(sidebarConfigMapped.DEFAULTS);
        sidebarConfig.push(sidebarConfigMapped.DRIVE);
        sidebarConfig.push(sidebarConfigMapped.PAYMENT_LINKS);
        sidebarConfig.push(sidebarConfigMapped.COMPANIES);
        sidebarConfig.push(sidebarConfigMapped.BLACKLIST);
        sidebarConfig.push(sidebarConfigMapped.MESSAGE_QUEUE);
        sidebarConfig.push(sidebarConfigMapped.BANNERS);
        sidebarConfig.push(sidebarConfigMapped.COMMUNICATION_LOGS);
        sidebarConfig.push(sidebarConfigMapped.TESTING_BENCH);
    }

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    let Auth = CommunicationService.getUser();

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': {height: '100%', display: 'flex', flexDirection: 'column'}
            }}
        >
            <Box sx={{px: 2.5, py: 3}}>
                <Box component={RouterLink} to="/" sx={{display: 'inline-flex'}}>
                    <Logo/>
                </Box>
            </Box>

            <Box sx={{mb: 5, mx: 2.5}}>
                <Link underline="none" component={RouterLink} to="#">
                    <AccountStyle>
                        <Avatar src={account.photoURL} alt="photoURL"/>
                        <Box sx={{ml: 2}}>
                            <Typography variant="body2" sx={{color: 'text.primary'}}>
                                {Auth.username}
                            </Typography>
                        </Box>
                    </AccountStyle>
                </Link>
            </Box>

            <NavSection navConfig={sidebarConfig}/>

            <Box sx={{flexGrow: 1}}/>
        </Scrollbar>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {width: DRAWER_WIDTH}
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
