import * as Yup from 'yup';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {useNavigate, useSearchParams} from 'react-router-dom';
import faker from 'faker';
import * as CommunicationService from '../../../services/CommunicationService'
import * as LogicService from '../../../services/LogicService'
// material
import {
    Box,
    Button, ButtonGroup,
    Checkbox,
    FormControlLabel, FormGroup,
    InputAdornment, InputLabel, MenuItem, Select,
    Stack,
    TextField,
    ToggleButton,
    Typography
} from '@mui/material';
import Popover from '@mui/material/Popover';
import {ToggleButtonGroup} from '@mui/lab';
import CountrySelect from "./CountrySelect";
import * as Countries from "../../../constants/Countries";
import * as Reasons from "../../../constants/Reasons";
import * as Languages from "../../../constants/Languages";
import TextSelectInput from "./TextSelectInput";
import APIConstants from "../../../constants/APIConstants";
import Constants from "../../../Constants";
import * as AlertMapper from "../../../services/AlertMapper";
import "./OrderForm.css"
import LanguageSelect from "./LanguageSelect";
import TextSelectInputV2 from "./TextSelectInputV2";
import Autocomplete from '@mui/material/Autocomplete';
import emailMisspelled, {top100} from "email-misspelled"
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import AnafSearchFields from "./AnafSearchFields";
const emailChecker = emailMisspelled({maxMisspelled: 2, lengthDiffMax: 5, domains: top100})
// ----------------------------------------------------------------------


export default function OrderForm({changeStepper, setFirstStepActive}) {
    const [searchParams] = useSearchParams();
    let personType = searchParams.get("person-type");

    const navigate = useNavigate();
    const [isItemSelected, changeItemSelected] = useState(false);
    let browserData = LogicService.getBrowserData();
    const [HIDE_REGIM_URGENT, SET_HIDE_REGIM_URGENT] = useState(false);
    const [HIDE_APOSTILA, SET_HIDE_APOSTILA] = useState(false);
    const [HIDE_TRANSLATION, SET_HIDE_TRANSLATION] = useState(false);
    // const [ANAF_API_INTEGRATION, SET_ANAF_API_INTEGRATION] = useState(false);
    const REASONS = Reasons.reasons.map(r => <MenuItem value={r.name}>{r.name}</MenuItem>);
    let countryToChooseAsDefault = Countries.getCountryByName('Romania');
    if (!APIConstants.DEV_MODE) {
        if (browserData === undefined || browserData === null ||
            browserData.country_name === undefined || browserData.country_name === null) {
        } else {
            countryToChooseAsDefault = Countries.getCountryByName(browserData.country_name);
        }
    }
    let languageToChooseAsDefault = Languages.getLanguageByName('ROMÂNĂ');
    const [country, changeCountry] = useState(countryToChooseAsDefault);
    let changeCountryOption = (value) => {
        if (value == undefined) {
            return changeCountry(value);
        }
        if (value.code != 'RO') {
            setDeliveryMessage(needMessages.MESSAGE_DELIVERY_DHL);
        } else if (value.code == 'RO') {
            let reasonVal = getFieldProps("reason").value;
            let reasonIndex = Reasons.reasons.findIndex(r => r.name === reasonVal);
            let reasonObj = Reasons.reasons[reasonIndex];
            if (reasonObj.physicallyMandatory == true) {
                setDeliveryMessage(needMessages.MESSAGE_DELIVERY_FAN);
            } else {
                setDeliveryMessage(null);
            }
        } else {
            setDeliveryMessage(null);
        }
        changeCountry(value);
    }
    const [phoneCountry, changePhoneCountry] = useState(countryToChooseAsDefault);
    const [translationCountry, changeTranslationCountry] = useState(languageToChooseAsDefault);
    const [judet, changeJudet] = useState(null);
    const [localitate, changeLocalitate] = useState(null);
    const [strada, changeStrada] = useState(null);
    const [strazi, changeStrazi] = useState([]);
    const [postal, setPostal] = useState(null);
    const [semiPhase, setSemiPhase] = useState(true);
    const [firmAdmin, setFirmAdmin] = useState(true);
    const [manuallyCompletedStreet, setManuallyCompletedStreet] = useState(false);
    const [manuallyCompletedCod, setManuallyCompletedCod] = useState(false);
    const [userIsForeign, setUserIsForeign] = React.useState(false);

    const modifyJudet = (judet) => {
        changeJudet(judet);
        changeLoc(null);
    }

    useEffect(() => {
        LogicService.getFeatureSwitchCallback('HIDE_REGIM_URGENT', (data, err) => {
            if (err) {
                return console.error(err);
            }
            if (data != null) {
                let hideRegimUrgent = data.value;
                SET_HIDE_REGIM_URGENT(hideRegimUrgent);
                setRegim(hideRegimUrgent ? 'standard' : 'urgent');
            }
        });

        LogicService.getFeatureSwitchCallback('HIDE_APOSTILA', (data, err) => {
            if (err) {
                return console.error(err);
            }
            if (data != null) {
                let hideApostila = data.value;
                SET_HIDE_APOSTILA(hideApostila);
            }
        });

        LogicService.getFeatureSwitchCallback('HIDE_TRANSLATION', (data, err) => {
            if (err) {
                return console.error(err);
            }
            if (data != null) {
                let hideApostila = data.value;
                SET_HIDE_TRANSLATION(hideApostila);
            }
        });

        // LogicService.getFeatureSwitchCallback('ANAF_API_INTEGRATION', (data, err) => {
        //     if (err) {
        //         return console.error(err);
        //     }
        //     if (data != null) {
        //         let hideApostila = data.value;
        //         SET_ANAF_API_INTEGRATION(hideApostila);
        //     }
        // });
    }, [])

    const changeLoc = (loc) => {
        changeLocalitate(loc);
        Countries.getStreets(judet, loc)
            .then(res => {
                changeStrada(null)
                changeStrazi(res)
            })
            .catch(err => {
                console.error(err)
            })
    }

    let RegisterSchema = Yup.object().shape({
        lastName: Yup.string().required('Prenumele este necesar').matches(/.*/, 'Prenumele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
        firstName: Yup.string().required('Numele este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
        cui: Yup.string().required('CUI-ul este necesar').matches(/.*/, 'CUI-ul este invalid').min(3, 'Prea scurt').max(20, 'Prea lung'),
        phoneNumber: Yup.string().required('Numărul de telefon este necesar').min(5, 'Prea scurt').max(40, 'Prea lung').matches(/\d*/, 'Detaliile sunt invalide'),
        deliveryPhoneNumber: Yup.string().required('Numărul de telefon este necesar').min(5, 'Prea scurt').max(40, 'Prea lung').matches(/\d*/, 'Detaliile sunt invalide'),
        district: Yup.string().required('Județul este necesar').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Județul este invalid'),
        city: Yup.string().required('Localitatea este necesară').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Localitatea este invalidă'),
        str: Yup.string().required('Strada este necesară').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Strada este invalidă'),
        postalCode: Yup.string().required('Codul poștal este necesar').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Codul poștal este invalid'),
        details: Yup.string().required('Detaliile sunt necesare').min(1, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Detaliile sunt invalide'),
        reason: Yup.string().required('Motivul este necesar').min(3, 'Prea scurt').max(250, 'Prea lung'),
        email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este necesar').max(50, 'Prea lung'),

        fatherName: Yup.string().required('Numele tatalui este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
        motherName: Yup.string().required('Numele mamei este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
    });


    const formik = useFormik({
        initialValues: {
            firstName: searchParams.get("firstName") == null ? '' : searchParams.get("firstName"),
            lastName: searchParams.get("lastName") == null ? '' : searchParams.get("lastName"),
            cui: '',
            email: searchParams.get("email") == null ? '' : searchParams.get("email"),
            phoneNumber: searchParams.get("phone") == null ? '' : searchParams.get("phone"),
            deliveryPhoneNumber: '',
            district: '',
            city: '',
            str: '',
            numar: '',
            scara: '',
            etaj: '',
            apartament: '',
            details: '',
            postalCode: '',
            reason: '',
            fatherName: '',
            motherName: '',
            foreignBirthplace: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });


    const normalizePhoneNumber = (prefix, phoneNo) => {
        if (prefix === undefined || prefix === null) {
            return phoneNo;
        }

        let prefixLen = prefix.length;
        for (let i = 0; i < prefixLen; i++) {
            let subStrToVerify = prefix.substring(i, prefixLen);
            if (phoneNo.startsWith(subStrToVerify)) {
                return '00' + prefix.substring(0, i) + phoneNo;
            }
        }
        return '00' + prefix + phoneNo;
    }

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 10000);
    }

    const semiPhaseTransition = () => {
        let order = {}

        if (regim === null || regim === undefined) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie selectat un regim',
                key: 'invalid_regim',
                severity: 'error'
            });
            return;
        }

        if (travel === null || travel === undefined) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie selectat un tip de livrare',
                key: 'invalid_livrare',
                severity: 'error'
            });
            return;
        }

        if (legalizedSelected && (translationCountry === null || translationCountry === undefined)) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie selectată limba traducerii',
                key: 'invalid_livrare',
                severity: 'error'
            });
            return;
        }

        if (travel === 'address') {
            let countryIsRomania = country.code === 'RO';

            if (countryIsRomania) {
                if (judet === undefined || judet == null) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Judetul este necesar',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
                if (localitate === undefined || localitate == null) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Orasul este necesar',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
                if (strada === undefined || strada == null) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Strada este necesara',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
                if (postal === undefined || postal == null) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Codul postal este necesar',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
            } else {
                if (getFieldProps('district').value == null || getFieldProps('district').value === undefined
                    || getFieldProps('district').value.length < 3) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Judetul este necesar',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
                if (getFieldProps('city').value == null || getFieldProps('city').value === undefined
                    || getFieldProps('city').value.length < 3) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Orasul este necesar',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
                if (getFieldProps('str').value == null || getFieldProps('str').value === undefined
                    || getFieldProps('str').value.length < 3) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Strada este necesara',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
                if (getFieldProps('postalCode').value == null || getFieldProps('postalCode').value === undefined
                    || getFieldProps('postalCode').value.length < 3) {
                    return LogicService.setAlertMessageAndSeverity({
                        message: 'Codul postal este necesar',
                        key: 'invalid_livrare', severity: 'error'
                    });
                }
            }
            // if (getFieldProps('details').value == null || getFieldProps('details').value === undefined
            //     || getFieldProps('details').value.length < 1) {
            //     return LogicService.setAlertMessageAndSeverity({
            //         message: 'Detaliile sunt necesare',
            //         key: 'invalid_livrare', severity: 'error'
            //     });
            // }
            if (getFieldProps('deliveryPhoneNumber').value == null || getFieldProps('deliveryPhoneNumber').value === undefined
                || getFieldProps('deliveryPhoneNumber').value.length < 3) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Telefonul pentru livrare este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
        }

        let phoneNumber = normalizePhoneNumber(phoneCountry.phone, getFieldProps('phoneNumber').value);
        order = {
            username: getFieldProps('email').value,
            phoneNumber: phoneNumber,
            userDetails: {
                type: alignment,
                firstName: getFieldProps('firstName').value,
                lastName: getFieldProps('lastName').value,
                cui: getFieldProps('cui').value,
                firmAdmin: firmAdmin
            },
            legalized: legalizedSelected,
            translationLanguage: legalizedSelected ? translationCountry.label : "ROMÂNĂ",
            regime: regim,
            travel: travel,
            apostila: apostilaSelected,
            delivery: travel,
            country: country.label,
            reason: getFieldProps('reason').value,
            browserData: browserData
        }
        if (userIsForeign) {
            order.userDetails.fatherName = getFieldProps('fatherName').value;
            order.userDetails.motherName = getFieldProps('motherName').value;
            order.userDetails.foreignBirthplace = getFieldProps('foreignBirthplace').value;
            if (foreignCitizen === 'address_foreign_ue') {
                order.regime = 'ADDRESS_FOREIGN_UE';
            }
        }
        if (deliverySelected) {
            let toSendPhoneNumber = normalizePhoneNumber(country.phone, getFieldProps('deliveryPhoneNumber').value);
            let countryIsRomania = country.code === 'RO';
            let district = null;
            let city = null;
            let postalCode = null;
            let details = getFieldProps('details').value;
            let street = "";
            if (countryIsRomania) {
                district = judet.name;
                city = localitate.name;
                postalCode = postal;
                street = strada;
            } else {
                district = getFieldProps('district').value;
                city = getFieldProps('city').value;
                postalCode = getFieldProps('postalCode').value;
                street = getFieldProps('str').value;
            }
            order.address = {
                country: country.label,
                district: district,
                city: city,
                street: street,
                numar: getFieldProps('numar').value,
                scara: getFieldProps('scara').value,
                etaj: getFieldProps('etaj').value,
                apartament: getFieldProps('apartament').value,
                details: getFieldProps('details').value,
                postalCode: postalCode,
                phoneNumber: toSendPhoneNumber,
                manuallyCompleted: (manuallyCompletedStreet || manuallyCompletedCod)
            }
        }

        disableTemporarily();
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ORDER_IS_COMPUTING);
        CommunicationService.placeOrder(order)
            .then((response) => {
                CommunicationService.setToken(response.jwtResponse);
                LogicService.setOrder(response.orderId, response.price, undefined, response.paymentCode, response.necessaryDocuments, alignment, order);
                navigate('/order-offer');
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const [emailSuggestions, setEmailSuggestions] = React.useState([]);
    const [emailSuggestionTriggered, setEmailSuggestionTriggered] = React.useState(false);

    const buttonPress = () => {

        if (personTypeControls === undefined || personTypeControls === null || Boolean(personTypeControls.value === '')) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie selectat tipul persoanei',
                key: 'invalid_cui', severity: 'error'
            });
        }

        let email = getFieldProps('email').value;

        if (!emailSuggestionTriggered) {
            let suggestions = emailChecker(email);
            if (suggestions != undefined && suggestions != null && suggestions.length != 0) {
                setEmailSuggestions(suggestions);
                handleEmailAnchorClick();
                setEmailSuggestionTriggered(true);
                return;
            }
        }

        if (Boolean(touched.email && errors.email) || email.length < 3) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Emailul este necesar',
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (country === null || country === undefined) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Tara este necesara',
                key: 'invalid_cui', severity: 'error'
            });
        }

        let phoneValue = getFieldProps('phoneNumber').value;
        if (Boolean(touched.phoneNumber && errors.phoneNumber) || phoneValue.length < 3) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Telefonul este necesar',
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (phoneValue.match(/^\d+$/) === null) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Telefonul poate să conțină doar cifre',
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (phoneCountry.code === 'RO' && phoneValue.match(/^[0]{0,1}7\d{8}$/) === null) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Numărul de telefon nu este valid.',
                key: 'invalid_phone',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls.value === 'business' && (Boolean(touched.cui && errors.cui)
            || getFieldProps('cui').value.length < 3)) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie completat câmpul CUI',
                key: 'invalid_cui',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls.value === 'business' && (Boolean(touched.cui && errors.cui)
            || getFieldProps('firstName').value.length < 1)) {
            if (anafSearchedCompany == null) {
                searchCompanyInAnafDB();
                return;
            }
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie completat câmpul Nume firma',
                key: 'invalid_cui',
                severity: 'error'
            });
            return;
        }

        if (personTypeControls.value === 'person' && (Boolean(touched.firstName && errors.firstName)
            || Boolean(touched.lastName && errors.lastName)
            || getFieldProps('firstName').value.length < 3
            || getFieldProps('lastName').value.length < 3)
        ) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie completat câmpurile de nume',
                key: 'invalid_name',
                severity: 'error'
            });
            return;
        }

        if (userIsForeign === true && (foreignCitizenTypeControls.value === null || foreignCitizenTypeControls.value === undefined || foreignCitizenTypeControls.value === '')) {
            LogicService.setAlertMessageAndSeverity({
                message: 'Trebuie selectat locul nașterii',
                key: 'invalid_regim',
                severity: 'error'
            });
            return;
        }

        if (Boolean(touched.reason && errors.reason) || getFieldProps('reason').value.length < 3) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Motivul este necesar',
                key: 'invalid_cui', severity: 'error'
            });
        }

        if (Boolean(touched.reason && errors.reason) || getFieldProps('reason').value.length < 3) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Motivul este necesar',
                key: 'invalid_cui', severity: 'error'
            });
        }

        changeStepper();
        window.scrollTo(0, 0);
        setSemiPhase(false);
    }

    const {errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError} = formik;

    const getToggleButton = (text, value, key, icon, changeVariable, changeMethod, hideRegimUrgent = false) => {
        return <ToggleButton value={value} key={key} selected={changeVariable} disabled={hideRegimUrgent}
                             onChange={() => {
                                 changeMethod(!changeVariable);
                             }}>
            <Box component="img" src={icon} width={{xs: 20, sm: 34}}/>
            <Typography variant={{xs: 'body2', sm: 'subtitle1'}} sx={{padding: '0 10px', marginTop: '10px'}}
                        gutterBottom>{text}</Typography>
        </ToggleButton>
    }
    const getSpecialToggleButton = (text, value, key, icon, hideRegimUrgent = false) => {
        return <ToggleButton value={value} key={key} disabled={hideRegimUrgent}>
            <Box component="img" src={icon} width={{xs: 20, sm: 34}}/>
            <Typography variant={{xs: 'body2', sm: 'subtitle1'}} sx={{padding: '0 10px', marginTop: '10px'}}
                        gutterBottom>{text}</Typography>
        </ToggleButton>
    }

    const personSelect = [
        getToggleButton('Persoană fizică', 'person', 'person', "/static/illustrations/businessman.png"),
        getToggleButton('Persoană juridică', 'business', 'business', "/static/illustrations/online-shop.png")
    ];

    const foreignCitizenSelect = [
        getToggleButton('Născut în uniunea europeană', 'address_foreign_ue', 'address_foreign_ue', "/static/illustrations/european-union.png"),
        getToggleButton('Născut în afara uniunii europene', 'address_foreign_non_ue', 'address_foreign_non_ue', "/static/illustrations/earth.png")
    ];

    const travelSelect = [
        getToggleButton('Electronic', 'electronic', 'electronic', "/static/illustrations/email.png"),
        getToggleButton('Electronic & Livrare la adresă', 'address', 'address', "/static/illustrations/delivery-truck.png")
    ];

    let regimSelect = [
        getSpecialToggleButton('Urgent - 1 zi lucrătoare', 'urgent', 'urgent', "/static/illustrations/calendar_1_day.png", HIDE_REGIM_URGENT),
        getSpecialToggleButton('Standard - 5 zile lucrătoare', 'standard', 'standard', "/static/illustrations/calendar_5_day.png", false)
    ];

    let initialPersonType = '';
    if (['person', 'business'].includes(personType)) {
        initialPersonType = personType;
    }

    if (searchParams.get("firstName") != null || searchParams.get("lastName") != null) {
        initialPersonType = 'person';
    }


    const [alignment, setAlignment] = React.useState(initialPersonType);
    const [foreignCitizen, setForeignCitizen] = React.useState('');
    const [selectedReasonObject, setSelectedReasonObject] = React.useState({});
    const [travel, setTravel] = React.useState('electronic');
    const [regim, setRegim] = React.useState(HIDE_REGIM_URGENT ? 'standard' : 'urgent');
    const [legalizedSelected, seLegalizedSelected] = React.useState(false);
    const [apostilaSelected, seApostilaSelected] = React.useState(false);
    const [deliverySelected, seDeliverySelected] = React.useState(false);
    const [youNeedSomethingMessage, setYouNeedSomethingMessage] = React.useState(null);
    const [deliveryMessage, setDeliveryMessage] = React.useState(null);
    const [selectedDeliveryPhone, setSelectedDeliveryPhone] = React.useState(null);


    const changeApostila = (state) => {
        if (state === true) {
            seLegalizedSelected(true);
            seApostilaSelected(true);
        } else {
            seApostilaSelected(false);
        }
    }

    const handleRegimChange = (event, newRegim) => {
        setRegim(newRegim);
    };

    const handleTravelChange = (event, newTravel) => {
        seDeliverySelected(newTravel === 'address');
        verifyDeliveryMessage(undefined, newTravel === 'address');
        setTravel(newTravel);
    };

    const handlePersonTypeChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const handleForeignCitizenTypeChange = (event, newAlignment) => {
        setForeignCitizen(newAlignment);
    };

    const personTypeControls = {
        value: alignment,
        onChange: handlePersonTypeChange,
        exclusive: true,
    };

    const foreignCitizenTypeControls = {
        value: foreignCitizen,
        onChange: handleForeignCitizenTypeChange,
        exclusive: true,
    };

    const travelTypeControls = {
        value: travel,
        onChange: handleTravelChange,
        exclusive: true,
    };

    const regimTypeControls = {
        value: regim,
        onChange: handleRegimChange,
        exclusive: true,
    };

    const generateRandomStuff = () => {
        let name = faker.name;
        let address = faker.address;
        seLegalizedSelected(false)
        seApostilaSelected(false)
        setAlignment('person')
        setFieldValue('email', name.firstName() + '.' + name.lastName() + "@gmail.com")
        setFieldValue('phoneNumber', "756846945")
        setFieldValue('firstName', name.firstName())
        setFieldValue('lastName', name.lastName())
        setFieldValue('cui', address.zipCode())
        setFieldValue('country', address.country())
        setFieldValue('district', address.city())
        setFieldValue('city', address.city())
        setFieldValue('postalCode', address.zipCode())
        setFieldValue('details', address.streetAddress())
        setFieldValue('reason', address.streetName())
    }


    const processReasonChange = (event) => {
        let newText = event.target.value;
        setFieldValue('reason', newText)
        let reasonIndex = Reasons.reasons.findIndex(r => r.name === newText);
        let reasonObj = Reasons.reasons[reasonIndex];
        if (reasonObj.physicallyMandatory == true) {
            seDeliverySelected(true);
            setTravel('address');
            // setDeliveryMessage(needMessages.MESSAGE_DELIVERY_FAN);
        } else {
            seDeliverySelected(false);
            setTravel('electronic');
            setDeliveryMessage(null);
        }
        verifyDeliveryMessage(newText);
    }

    const needMessages = {
        APOSTILA: "Pentru motivul solicitat de dvs. este nevoie de apostilă. Vă sugerăm să optați această opțiune.",
        DELIVERY: "Pentru motivul solicitat de dvs. este nevoie de document în original. Vă sugerăm să optați pentru livrare.",
        BOTH: "Pentru motivul solicitat de dvs. este nevoie de document în original cu apostilă. Vă sugerăm să optați pentru ambele opțiuni.",
        MESSAGE_DELIVERY_DHL: "Livrare rapidă prin DHL",
        MESSAGE_DELIVERY_FAN: "Livrare prin FanCourier - cost aproximativ 20 lei"
    }

    const verifyDeliveryMessage = (reason, deliverySel) => {
        let newText = reason || getFieldProps('reason').value;
        deliverySel = deliverySel !== undefined ? deliverySel : deliverySelected;
        let text = null;
        let apostilaFound = Constants.APOSTILA_MANDATORY_ARRAY.find(el => newText.toLowerCase().includes(el.toLowerCase()));
        let deliveryFound = Constants.DELIVERY_MANDATORY_ARRAY.find(el => newText.toLowerCase().includes(el.toLowerCase()));

        if (apostilaFound && deliveryFound) {
            text = needMessages["BOTH"];
        } else if (apostilaFound) {
            text = needMessages["APOSTILA"];
        } else if (deliveryFound) {
            text = needMessages["DELIVERY"];
        }
        if (text !== null && !deliverySel) {
            return setYouNeedSomethingMessage(text);
        }
        setYouNeedSomethingMessage(null);
    }


    const [popoverEmailAnchor, setPopoverEmailAnchor] = React.useState(null);
    const [popoverEmailAnchorOpen, setPopoverEmailAnchorOpen] = React.useState(false);

    const handleEmailAnchorClick = () => {
        setPopoverEmailAnchorOpen(true)
    };

    const handleEmailAnchorClose = () => {
        setPopoverEmailAnchorOpen(false);
    };

    const handleEmailAnchorSelect = (correction) => {
        handleEmailAnchorClose();
        setFieldValue('email', correction)

    };

    const open = Boolean(popoverEmailAnchorOpen);
    const id = open ? 'simple-popover' : undefined;

    const [manualInput, setManualInput] = React.useState(false);
    const [anafSearchedCompany, setAnafSearchedCompany] = React.useState(null);
    const searchCompanyInAnafDB = () => {
        setManualInput(true)
        CommunicationService.getAnafCompany(parseInt(getFieldProps('cui').value))
            .then((response) => {
                setAnafSearchedCompany(response.denumire);
                setFieldValue('firstName', response.denumire)
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ANAF_CUI_NOT_FOUND)
            });

    }

    let semiPhaseOne = <Form autoComplete="on" onSubmit={handleSubmit}>
        <Stack spacing={3}>
            <TextField
                aria-describedby={id}
                fullWidth
                autoComplete="email"
                type="email"
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onMouseOver={(e) => {
                    if (popoverEmailAnchor != null) {
                        return;
                    }
                    setPopoverEmailAnchor(e.currentTarget);
                }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={popoverEmailAnchor}
                onClose={handleEmailAnchorClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="text"
                >
                    <Typography sx={{p: 1, pb: 2}} color={'error'} variant="body2">Adresa dvs. de email pare greșită. Vă
                        sugerăm: </Typography>
                    {
                        emailSuggestions.map(e => <Button className={"lwrButton"}
                                                          key={"email-correction-" + e.corrected}
                                                          onClick={() => handleEmailAnchorSelect(e.corrected)}
                                                          fullWidth={true} style={{
                            justifyContent: "flex-start",
                            paddingLeft: '15px'
                        }}>{e.corrected}</Button>)
                    }
                    <Button className={"lwrButton"} key="email-correction-original"
                            onClick={() => handleEmailAnchorClose()}
                            fullWidth={true} style={{
                        justifyContent: "flex-start",
                        paddingLeft: '15px'
                    }}>{getFieldProps('email').value}</Button>
                </ButtonGroup>


            </Popover>

            <Stack direction={{xs: 'column', sm: 'row'}} className="phone-number-combined">
                <CountrySelect defaultValue={phoneCountry} label={"Prefix"} selectOption={(option) => {
                    changePhoneCountry(option);
                }}/>
                <TextField
                    className={"phone-number-text-field"}
                    fullWidth
                    autoComplete="phoneNumber"
                    label="Număr de telefon"
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    disabled={phoneCountry === null || phoneCountry === undefined}
                    InputProps={{
                        startAdornment:
                            (phoneCountry === null || phoneCountry === undefined) ? null :
                                <InputAdornment position="start">+{phoneCountry.phone}</InputAdornment>,
                    }}
                />
            </Stack>

            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                <ToggleButtonGroup size="large" {...personTypeControls}>
                    {personSelect}
                </ToggleButtonGroup>
            </Stack>

            {
                Boolean(personTypeControls.value !== 'person') ? null :
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                        <TextField
                            fullWidth
                            label="Nume"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                        />

                        <TextField
                            fullWidth
                            label="Prenume"
                            {...getFieldProps('lastName')}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                        />
                    </Stack>
            }

            {
                Boolean(personTypeControls.value !== 'business') ? null :
                    // Boolean(ANAF_API_INTEGRATION) ?
                    <AnafSearchFields
                        getFieldProps={getFieldProps}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        anafSearchedCompany={anafSearchedCompany}
                        setAnafSearchedCompany={setAnafSearchedCompany}
                        manualInput={manualInput} setManualInput={setManualInput}
                    />
                // : <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                //     <TextField
                //         fullWidth
                //         label="CUI"
                //         {...getFieldProps('cui')}
                //         error={Boolean(touched.cui && errors.cui)}
                //         helperText={touched.cui && errors.cui}
                //     />
                //     <TextField
                //         fullWidth
                //         label="Nume Firma"
                //         {...getFieldProps('firstName')}
                //         error={Boolean(touched.firstName && errors.firstName)}
                //         helperText={touched.firstName && errors.firstName}
                //     />
                // </Stack>

            }
            {/*{*/}
            {/*    Boolean(personTypeControls.value !== 'business') ? null :*/}
            {/*        <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox checked={firmAdmin}*/}
            {/*                          onChange={() => setFirmAdmin(!firmAdmin)}*/}
            {/*                          sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}*/}
            {/*                />}*/}

            {/*            label={"Sunt administratorul firmei, împuternicirea nu este necesară"}>*/}
            {/*        </FormControlLabel>*/}
            {/*}*/}
            {/*{*/}
            {/*    Boolean(personTypeControls.value === '') ? null :*/}
            {/*        <TextField*/}
            {/*            fullWidth*/}
            {/*            label="Motivul solicitării (scris pe cazier)"*/}
            {/*            {...getFieldProps('reason')}*/}
            {/*            error={Boolean(touched.reason && errors.reason)}*/}
            {/*            onChange={processReasonChange}*/}
            {/*            helperText={touched.reason && errors.reason}*/}
            {/*        />*/}
            {/*}*/}
            {/*{*/}
            {/*    Boolean(personTypeControls.value === '') ? null :*/}
            {/*        <Autocomplete*/}
            {/*        error={Boolean(touched.reason && errors.reason)}*/}
            {/*        defaultValue={getFieldProps('reason').value}*/}
            {/*        onChange={(event, value) => processReasonChange(value)}*/}
            {/*        filterOptions={(options, state) => options}*/}
            {/*        options={Reasons.reasons}*/}
            {/*        renderInput={(params) => <TextField {...params} label="Motivul solicitării (scris pe cazier)" />}*/}
            {/*    />*/}
            {/*}*/}
            {
                Boolean(personTypeControls.value !== 'person') ? null :
                    <Stack direction={{xs: 'column', sm: 'row'}} sx={{marginTop: '10px !important'}} alignItems="center"
                           justifyContent="center"
                    >
                        {/*<ToggleButton*/}
                        {/*    variant="contained"*/}
                        {/*    selected={userIsForeign}*/}
                        {/*    onClick={() => {*/}
                        {/*        setUserIsForeign(!userIsForeign);*/}
                        {/*    }}*/}
                        {/*    sx={{padding: '5px 10px'}}*/}
                        {/*>*/}
                        {/*    <Box component="img" src={"/static/illustrations/foreign.png"} alt={"Sunt cetățean străin"}*/}
                        {/*         title={"Sunt cetățean străin"}*/}
                        {/*         sx={{width: 30}}/> &nbsp;&nbsp; {"Sunt cetățean străin"}*/}
                        {/*</ToggleButton>*/}


                        <FormGroup>
                            <FormControlLabel id={"foreign-checkbox"} control={
                                <Checkbox
                                    value={userIsForeign}
                                    onClick={() => setUserIsForeign(!userIsForeign)}
                                />
                            } label="Sunt cetățean străin"/>
                        </FormGroup>

                    </Stack>
            }
            {
                Boolean(userIsForeign) != true ? null :
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                        <TextField
                            fullWidth
                            label="Numele tatălui"
                            {...getFieldProps('fatherName')}
                            error={Boolean(touched.fatherName && errors.fatherName)}
                            helperText={touched.fatherName && errors.fatherName}
                        />
                        <TextField
                            fullWidth
                            label="Numele mamei"
                            {...getFieldProps('motherName')}
                            error={Boolean(touched.motherName && errors.motherName)}
                            helperText={touched.motherName && errors.motherName}
                        />
                    </Stack>
            }
            {
                Boolean(userIsForeign) != true ? null :
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                        <TextField
                            fullWidth
                            label="Locul nașterii"
                            {...getFieldProps('foreignBirthplace')}
                            error={Boolean(touched.foreignBirthplace && errors.foreignBirthplace)}
                            helperText={touched.foreignBirthplace && errors.foreignBirthplace}
                        />
                    </Stack>
            }
            {
                Boolean(userIsForeign) != true ? null :
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={0} alignSelf={"center"}>
                        <ToggleButtonGroup size="small" {...foreignCitizenTypeControls}>
                            {foreignCitizenSelect}
                        </ToggleButtonGroup>
                    </Stack>
            }
            {
                Boolean(personTypeControls.value === '') || Boolean(personTypeControls.value === null)
                || Boolean(personTypeControls.value === undefined) ? null :
                    <Stack direction={{xs: 'column', sm: 'column'}} spacing={0}>
                        <InputLabel id="demo-simple-select-label" sx={{marginLeft: '4px', marginBottom: '4px'}}>Motivul
                            solicitării (scris pe cazier)</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            error={Boolean(touched.reason && errors.reason)}
                            value={getFieldProps('reason').value}
                            onChange={(event) => processReasonChange(event)}
                        >
                            {REASONS}
                        </Select>
                    </Stack>


            }
            {
                Boolean(youNeedSomethingMessage !== null) ?
                    <Typography variant="body2" color={'error'} sx={{textAlign: "center"}}>
                        {youNeedSomethingMessage}
                    </Typography> : null
            }
            {/*{*/}
            {/*    Boolean(personTypeControls.value === '') ? null : <Stack direction="row" alignItems="center" spacing={2}>*/}
            {/*        <Box*/}
            {/*            component="img"*/}
            {/*            alt={"warning"}*/}
            {/*            src={"/static/illustrations/exclamation.png"}*/}
            {/*            sx={{width: 48, height: 48, borderRadius: 1.5}}*/}
            {/*        />*/}
            {/*        <Box sx={{minWidth: 240, maxWidth: "90%"}}>*/}
            {/*            <Typography variant="subtitle2">*/}
            {/*                {"Este interzisa solicitarea cazierului judiciar in numele altor persoane fara stirea acestora, acest fapt fiind pedepsit cu inchisoare de la 1 la 5 ani conform Art 325 din legea 286/2009 privind falsul informatic."}*/}
            {/*            </Typography>*/}
            {/*            <Typography variant="subtitle2">*/}
            {/*                {"Continuarea solicitarii reprezinta luarea dumneavostra la cunostinta."}*/}
            {/*            </Typography>*/}
            {/*        </Box>*/}
            {/*    </Stack>*/}
            {/*}*/}
            <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={buttonPress}
                disabled={
                    !buttonEnabled ||
                    Boolean(personTypeControls.value === '') || Boolean(personTypeControls.value === null) || Boolean(personTypeControls.value === undefined)
                    // || Boolean(country === null || country === undefined)
                    // || !touched.email || Boolean(touched.email && errors.email)
                    // || !touched.phoneNumber || Boolean(touched.phoneNumber && errors.phoneNumber)
                    // || Boolean(touched.firstName && errors.firstName)
                    // || Boolean(touched.lastName && errors.lastName)
                    // || Boolean(touched.cui && errors.cui)
                    // || Boolean(touched.reason && errors.reason)
                    // || ((!touched.lastName || !touched.firstName) && !touched.cui)
                }
            >
                Către detalii
            </Button>
        </Stack>
    </Form>;

    let judete = Countries.getJudete();

    function searchPostalCode(name) {
        return strada === undefined || strada === null || name === undefined || name === null
            ? null : strada.coduriPostale.find(cp => cp === name);
    }

    function getCoduriPostale() {
        if (strazi == undefined || strazi == null || strada == undefined || strada == null) {
            return [];
        }
        let find = strazi.find(s => s.name == strada);
        if (find == undefined) {
            return [];
        }
        return find.coduriPostale;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openInfoPopover = Boolean(anchorEl);

    let semiPhaseTwo = <Form autoComplete="on" onSubmit={handleSubmit}>
        <Stack spacing={3}>
            {
                Boolean(personTypeControls.value === '') ? null :
                    <Stack direction={{xs: 'column', sm: 'column'}} spacing={1}>
                        <Stack direction="column" justifyContent="center" border={"1px solid rgba(0, 0, 0, 0.15)"}
                               sx={{display: HIDE_TRANSLATION ? 'none': 'block'}}
                               padding={"10px"} borderRadius={"10px"}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}
                                   justifyContent={HIDE_APOSTILA ? "space-around" : "space-between"}
                                   padding={"10px"} borderRadius={"10px"}>
                                {
                                    getToggleButton('Traducere legalizată', 'legalized', 'legalized',
                                        "/static/illustrations/legalized.png", legalizedSelected, seLegalizedSelected)
                                }
                                {
                                    HIDE_APOSTILA === true ? null : getToggleButton('Apostilă Haga', 'apostila', 'apostila',
                                        "/static/illustrations/stamp.png", apostilaSelected, changeApostila)
                                }
                                {
                                    <Stack width={{xs: '100%', sm: '40%'}} alignSelf={"center"}>
                                        <LanguageSelect selectOption={changeTranslationCountry}
                                                        defaultValue={translationCountry}
                                                        disabled={Boolean(!legalizedSelected)}
                                                        label={"Limba traducerii"} disablePrefix
                                        />
                                    </Stack>
                                }

                            </Stack>
                            {/*<Stack direction="column" justifyContent="center" display={legalizedSelected === true || apostilaSelected === true ? "flex" : "none"}*/}
                            {/*       padding={"0px"} borderRadius={"10px"} color={"red"} >*/}
                            {/*    <Typography variant="body1" justifyContent="center" alignSelf="center">*/}
                            {/*        {"Biroul de traduceri/apostile este indisponibil până pe data de 8 august"}*/}
                            {/*    </Typography>*/}
                            {/*    <Typography variant="body1" justifyContent="center" alignSelf="center">*/}
                            {/*        {"Cazierul dumneavoastră în limba română este eliberat fără întârzieri"}*/}
                            {/*    </Typography>*/}
                            {/*</Stack>*/}
                        </Stack>

                        <Stack direction="column" justifyContent="center" border={"1px solid rgba(0, 0, 0, 0.15)"}
                               padding={"10px"} borderRadius={"10px"}>
                            <Typography variant="subtitle1" justifyContent="center" alignSelf="center">
                                {"Doriți documentul în regim ..."}
                            </Typography>
                            <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="center"
                                   margin={"10px 0 5px 0"}>
                                <ToggleButtonGroup size="large" {...regimTypeControls} className={"regimGroup"}>
                                    {
                                        (userIsForeign && foreignCitizen === 'address_foreign_ue') ?
                                            <Stack direction={{xs: 'row', sm: 'row'}} justifyContent="center">
                                                <ToggleButton value={'standard'} key={'standard'} selected={true}>
                                                    <Box component="img"
                                                         src={"/static/illustrations/calendar_10_day.png"}
                                                         width={{xs: 20, sm: 34}}/>
                                                    <Typography variant={{xs: 'body2', sm: 'subtitle1'}}
                                                                sx={{padding: '0 10px', marginTop: '10px'}}
                                                                gutterBottom>{'Standard - 10 zile lucrătoare'}</Typography>
                                                </ToggleButton>

                                                <InfoIcon aria-owns={openInfoPopover ? 'mouse-over-popover' : undefined}
                                                          aria-haspopup="true"
                                                          sx={{
                                                              width: '60',
                                                              height: '60',
                                                              marginTop: '15px',
                                                              marginLeft: '10px'
                                                          }}
                                                          fontSize={'large'}
                                                          onMouseEnter={handlePopoverOpen}
                                                          onClick={handlePopoverOpen}
                                                          onMouseLeave={handlePopoverClose}
                                                />
                                                <Popover
                                                    id="mouse-over-popover"
                                                    sx={{
                                                        pointerEvents: 'none'
                                                    }}
                                                    open={openInfoPopover}
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose}
                                                    disableRestoreFocus
                                                >
                                                    <Typography sx={{p: 2}}>Noua lege din România impune căutarea de
                                                        antecedente penale în baze de date internaționale pentru
                                                        persoane străine.
                                                        Acest lucru duce la mărirea timpului de finalizare a cazierului
                                                        la minim 10 zile lucrătoare.
                                                        La final veți primi adițional și un cazier european.
                                                    </Typography>
                                                </Popover>
                                            </Stack>
                                            : regimSelect
                                    }
                                </ToggleButtonGroup>
                            </Stack>
                        </Stack>

                        <Stack direction="column" justifyContent="center" border={"1px solid rgba(0, 0, 0, 0.15)"}
                               padding={"10px"} borderRadius={"10px"}>
                            <Typography variant="subtitle1" justifyContent="center" alignSelf="center">
                                {"Livrarea se va face ..."}
                            </Typography>
                            <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="center"
                                   margin={"10px 0 20px 0"}>
                                <ToggleButtonGroup size="large" {...travelTypeControls}>
                                    {travelSelect}
                                </ToggleButtonGroup>
                            </Stack>
                            {
                                Boolean(youNeedSomethingMessage !== null) ?
                                    <Typography variant="body2" color={'error'} sx={{textAlign: "center"}}
                                                padding={"0 50px"}>
                                        {youNeedSomethingMessage}
                                    </Typography> : null
                            }
                            {/*{*/}
                            {/*    Boolean(deliveryMessage !== null) && travel === 'address' ?*/}
                            {/*        <Typography variant="subtitle1" color={'secondary'} sx={{textAlign: "center"}}*/}
                            {/*                    padding={"0px 50px 20px 50px"}>*/}
                            {/*            {deliveryMessage}*/}
                            {/*        </Typography> : null*/}
                            {/*}*/}
                            {
                                Boolean(travel !== 'address') ? null :
                                    <Stack spacing={3}>
                                        {
                                            <Stack>
                                                {
                                                    Boolean(country !== null && country !== undefined && country.code === 'RO') ?
                                                    <Typography variant="subtitle1" color={'secondary'}
                                                                sx={{textAlign: "center"}}
                                                                padding={"0px 50px 20px 50px"}>
                                                        {needMessages.MESSAGE_DELIVERY_FAN}
                                                    </Typography> :
                                                        <Typography variant="subtitle1" color={'secondary'}
                                                                    sx={{textAlign: "center"}}
                                                                    padding={"0px 50px 20px 50px"}>
                                                            {needMessages.MESSAGE_DELIVERY_DHL}
                                                        </Typography>
                                                }
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>

                                                    <CountrySelect defaultValue={country} selectOption={(option) => {
                                                        changeCountryOption(option);
                                                        if (option !== null && option !== undefined && selectedDeliveryPhone !== option) {
                                                            setSelectedDeliveryPhone(option);
                                                            if (option === phoneCountry) {
                                                                setFieldValue('deliveryPhoneNumber', getFieldProps('phoneNumber').value)
                                                            } else {
                                                                setFieldValue('deliveryPhoneNumber', "")
                                                            }
                                                        }

                                                    }}/>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete="deliveryPhoneNumber"
                                                        label="Număr de telefon livrare"
                                                        {...getFieldProps('deliveryPhoneNumber')}
                                                        error={Boolean(touched.deliveryPhoneNumber && errors.deliveryPhoneNumber)}
                                                        helperText={touched.deliveryPhoneNumber && errors.deliveryPhoneNumber}
                                                        InputProps={{
                                                            startAdornment:
                                                                (country === null || country === undefined) ? null :
                                                                    <InputAdornment
                                                                        position="start">+{country.phone}</InputAdornment>,
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        }
                                        {
                                            Boolean(country !== null && country !== undefined && country.code === 'RO') ?
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                                    <TextSelectInput options={judete}
                                                                     selectOption={modifyJudet}
                                                                     label={"Alege județul"}
                                                                     parenthesisFieldName={'code'}
                                                                     searchFunction={(judet) => Countries.getJudetByName(judet)}
                                                                     disabled={country === null || country === undefined}
                                                    />
                                                    <TextSelectInput options={Countries.getLocalitati(judet)}
                                                                     disabled={judet === null || judet === undefined}
                                                                     label={"Alege localitatea"}
                                                                     parenthesisFieldName={'comuna'}
                                                                     searchFunction={(name) => Countries.getLocalitateByName(judet, name)}
                                                                     selectOption={changeLoc}
                                                    />
                                                </Stack> :
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                                    <TextField
                                                        fullWidth
                                                        label="Judet"
                                                        disabled={Boolean(country === null || country === undefined)}
                                                        {...getFieldProps('district')}
                                                        error={Boolean(touched.district && errors.district)}
                                                        helperText={touched.district && errors.district}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Localitate"
                                                        disabled={Boolean(country === null || country === undefined)}
                                                        {...getFieldProps('city')}
                                                        error={Boolean(touched.city && errors.city)}
                                                        helperText={touched.city && errors.city}
                                                    />
                                                </Stack>

                                        }
                                        {
                                            Boolean(country !== null && country !== undefined && country.code === 'RO') ?
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                                    {/*<TextSelectInputV2*/}
                                                    {/*    options={strazi}*/}
                                                    {/*    selectOption={changeStrada}*/}
                                                    {/*    label={"Alege strada"}*/}
                                                    {/*    searchFunction={(name) => Countries.getStreetByName(name)}*/}
                                                    {/*    disabled={localitate === null || localitate === undefined}*/}
                                                    {/*/>*/}
                                                    <TextSelectInputV2
                                                        name="Alegestrada"
                                                        label="Alege strada"
                                                        options={strazi.map(s => s.name)}
                                                        supOnChange={(value) => changeStrada(value)}
                                                        disabled={localitate === null || localitate === undefined}
                                                        setManuallyCompletedAddress={setManuallyCompletedStreet}
                                                    />
                                                    <TextSelectInputV2
                                                        name="Codpostal"
                                                        label="Cod poștal"
                                                        options={getCoduriPostale()}
                                                        supOnChange={(value) => setPostal(value)}
                                                        disabled={strada === null || strada === undefined}
                                                        setManuallyCompletedAddress={setManuallyCompletedCod}
                                                    />
                                                    {/*<TextSelectInput*/}
                                                    {/*    options={strada === undefined || strada === null ? [] : strada.coduriPostale}*/}
                                                    {/*    selectOption={setPostal}*/}
                                                    {/*    label={"Cod poștal"}*/}
                                                    {/*    searchFunction={(name) => searchPostalCode(name)}*/}
                                                    {/*    // disabled={strada === null || strada === undefined}*/}

                                                    {/*/>*/}
                                                </Stack> :
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                                    <TextField
                                                        fullWidth
                                                        label="Stada"
                                                        disabled={Boolean(country === null || country === undefined)}
                                                        {...getFieldProps('str')}
                                                        error={Boolean(touched.str && errors.str)}
                                                        helperText={touched.str && errors.str}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Cod poștal"
                                                        {...getFieldProps('postalCode')}
                                                        error={Boolean(touched.postalCode && errors.postalCode)}
                                                        helperText={touched.postalCode && errors.postalCode}
                                                    />
                                                </Stack>
                                        }
                                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Număr stradă"
                                                disabled={Boolean(country === null || country === undefined)}
                                                {...getFieldProps('numar')}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Bloc"
                                                disabled={Boolean(country === null || country === undefined)}
                                                {...getFieldProps('details')}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Scara"
                                                disabled={Boolean(country === null || country === undefined)}
                                                {...getFieldProps('scara')}
                                            />
                                        </Stack>
                                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Etaj"
                                                disabled={Boolean(country === null || country === undefined)}
                                                {...getFieldProps('etaj')}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Apartament"
                                                disabled={Boolean(country === null || country === undefined)}
                                                {...getFieldProps('apartament')}
                                            />
                                        </Stack>
                                        {/*{*/}
                                        {/*    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>*/}
                                        {/*        <TextField*/}
                                        {/*            fullWidth*/}
                                        {/*            label="Alte detalii"*/}
                                        {/*            disabled={Boolean(country === null || country === undefined)}*/}
                                        {/*            {...getFieldProps('details')}*/}
                                        {/*            error={Boolean(touched.details && errors.details)}*/}
                                        {/*            helperText={touched.details && errors.details}*/}
                                        {/*        />*/}
                                        {/*    </Stack>*/}
                                        {/*}*/}
                                    </Stack>
                            }
                        </Stack>

                    </Stack>
            }

            {/*{*/}
            {/*    Boolean(personTypeControls.value === '') ? null : <Stack direction="row" alignItems="center" spacing={2}>*/}
            {/*        <Box*/}
            {/*            component="img"*/}
            {/*            alt={"warning"}*/}
            {/*            src={"/static/illustrations/exclamation.png"}*/}
            {/*            sx={{width: 48, height: 48, borderRadius: 1.5}}*/}
            {/*        />*/}
            {/*        <Box sx={{minWidth: 240, maxWidth: "90%"}}>*/}
            {/*            <Typography variant="subtitle2">*/}
            {/*                {"Este interzisa solicitarea cazierului judiciar in numele altor persoane fara stirea acestora, acest fapt fiind pedepsit cu inchisoare de la 1 la 5 ani conform Art 325 din legea 286/2009 privind falsul informatic."}*/}
            {/*            </Typography>*/}
            {/*            <Typography variant="subtitle2">*/}
            {/*                {"Continuarea solicitarii reprezinta luarea dumneavostra la cunostinta."}*/}
            {/*            </Typography>*/}
            {/*        </Box>*/}
            {/*    </Stack>*/}
            {/*}*/}
            <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2}>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color={'warning'}
                    onClick={() => {
                        setSemiPhase(true);
                        setFirstStepActive(0);
                    }}
                >
                    Înapoi
                </Button>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={!buttonEnabled}
                    onClick={semiPhaseTransition}
                >
                    Obține cazier
                </Button>
            </Stack>
        </Stack>
    </Form>;

    return (
        <FormikProvider value={formik}>
            {
                APIConstants.DEV_MODE ? <FormControlLabel
                    label="DEV - Autocomplete"
                    control={
                        <Checkbox
                            checked={isItemSelected}
                            onChange={() => {
                                generateRandomStuff();
                                changeItemSelected(!isItemSelected);
                            }}
                        />
                    }
                /> : null
            }
            {
                semiPhase ? semiPhaseOne : semiPhaseTwo
            }
        </FormikProvider>
    );
}
