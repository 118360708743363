import {default as languageFile} from './languages.json'

let o = languageFile.languages;

const languageArray = Object.keys(o)
    .map(languageKey => {
        return {
            code: languageKey,
            label: o[languageKey].name,
            phone: '',
        }
    })
    .sort((k1, k2) => k1.label.localeCompare(k2.label))


const getLanguageByName = (name) => {
    if (name === undefined || name === null || name.length === 0) {
        return null;
    }
    let find = languageArray.find(c => c.label.toLowerCase() === name.toLowerCase());
    return find === undefined ? null : find;
}


export {
    languageArray,
    getLanguageByName
}