// material
import {Container, Grid} from '@mui/material';
// components
import Page from '../components/Page';
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import * as CommunicationService from "../services/CommunicationService";
import {Helmet} from "react-helmet-async";
import {useEffect} from "react";

// ----------------------------------------------------------------------


export default function PaymentRedirect() {
    let {orderId, orderCode} = useParams();

    let [confirmedAlready, setConfirmedAlready] = React.useState(false);
    let [text, setText] = React.useState("Se confirmă plata, vă rugăm așteptați!");
    const navigate = useNavigate();

    function confirmPayment(orderId, orderCode) {
        CommunicationService.confirmPayment(orderId, orderCode)
            .then((response) => {
                setTimeout(function () {
                    navigate('/')
                }, 5000);
                setText(response.message);
                setConfirmedAlready(true);
                //gtag_report_conversion(`confirm-payment/${orderId}/${orderCode}`, response.price);
            })
            .catch((error) => {
                setTimeout(function () {
                    navigate('/')
                }, 5000);
                setText(JSON.parse(error.message).message);
            });
    }

    if (!confirmedAlready) {
        confirmPayment(orderId, orderCode);
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Helmet>
                <meta name="googlebot" content="noindex"/>
                <meta name="robots" content="noindex"/>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <Container maxWidth="xl">
                <Grid container spacing={5} align={"center"}>


                    <Grid item xs={12} md={12} lg={12}>
                        <Typography component="div" variant="h3">
                            {text}
                        </Typography>

                        <Typography component="div" variant="h3" style={{marginTop: '5%'}}>
                            Veți fi redirectat pe pagina principală în 5 secunde ...
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Page>
    );
}
