// material
import {Container, Grid} from '@mui/material';
// components
import Page from '../components/Page';
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import * as CommunicationService from "../services/CommunicationService";
import {Helmet} from "react-helmet-async";
import {useEffect} from "react";

// ----------------------------------------------------------------------


export default function PaymentRedirectManual() {

    let [text, setText] = React.useState("Veți primi confirmarea plății după verificare, mulțumim pentru comandă!");
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(function () {
            navigate('/')
        }, 5000);
    }, []);


    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Helmet>
                <meta name="googlebot" content="noindex"/>
                <meta name="robots" content="noindex"/>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <Container maxWidth="xl">
                <Grid container spacing={5} align={"center"}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography component="div" variant="h3">
                            {text}
                        </Typography>
                        <Typography component="div" variant="h3" style={{marginTop: '5%'}}>
                            Veți fi redirectat pe pagina principală în 5 secunde ...
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
